import {
  IClanRoomsAdapted,
  IClanRoomsResponse,
  IGetRoomResponse,
} from 'api/types/room';
import { IGroupRooms, IRoom, IRoomParams } from 'interfaces';

export const roomAdapter = (data: IGetRoomResponse): IRoom => {
  const {
    livekit_name,
    name,
    num_participants,
    publisher_id,
    publisher_name,
    recording_started,
    active,
    created_at,
    location,
  } = data;

  return {
    livekitName: livekit_name,
    roomName: name,
    numParticipants: num_participants,
    accountId: publisher_id,
    publisherName: publisher_name,
    isRecording: recording_started,
    src: '',
    isActive: active,
    createdAt: created_at,
    location: {
      lat: location?.lat || 0,
      lon: location?.lon || 0,
    },
  };
};

export const getClanRoomsAdapter = (
  data: IClanRoomsResponse
): IClanRoomsAdapted => {
  const { clan_id, rooms } = data;

  return {
    clanId: clan_id,
    rooms: rooms.map(roomAdapter),
  };
};

export const groupRoomsAdapter = (
  rooms: IRoom[],
  clanId: string,
  initialRooms: Map<string, IGroupRooms>,
  hiddenRooms?: Pick<IRoomParams, 'clanId' | 'livekitName'>[]
) => {
  const groupRooms = rooms.reduce<IGroupRooms>(
    (acc, room) => {
      if (room.isActive) {
        acc.active.push(room);
      } else {
        acc.archive.push(room);
      }

      return acc;
    },
    { active: [], archive: [] }
  );

  const activeRooms = initialRooms.get(clanId)?.active;

  groupRooms.active.forEach((activeRoom) => {
    const room = activeRooms?.find(
      (room) => room.livekitName === activeRoom.livekitName
    );

    if (room) {
      activeRoom.isHidden = hiddenRooms?.some(
        (hiddenRoom) => hiddenRoom.livekitName === room.livekitName
      );

      if (room.token && !activeRoom.token) {
        activeRoom.token = room.token;
      }

      if (room.createdAt !== activeRoom.createdAt) {
        activeRoom.token = undefined;
      }
    }
  });

  return groupRooms;
};
