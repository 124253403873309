import { errorMessages, successMessages } from 'constants/messages';
import {
  ILoggedInRequest,
  ILoggedInResponse,
  ILoginResponse,
  IVerifyPasswordParams,
  IVerifyPasswordResponse,
} from 'interfaces';
import axios from 'services/axios';

import {
  IAccountPasswordThunkData,
  TAccountEmailThunkData,
} from './types/account';
import { requestHandler } from './utils/requestHandler';

export const login = requestHandler(() =>
  axios.post<ILoginResponse>(
    'login',
    {},
    { params: { access_type: 'offline' } }
  )
);

export const logout = requestHandler(() => axios.post('logout'));

export const loggedIn = requestHandler((data: ILoggedInRequest) =>
  axios.post<ILoggedInResponse>('logged-in', data)
);

export const changeEmail = requestHandler(
  (data: TAccountEmailThunkData) =>
    axios.post<ILoggedInResponse>('/change-email', data),
  {
    defaultErrorMessage: errorMessages.UPDATE_LOGIN,
    defaultSuccessMessage: successMessages.UPDATE_LOGIN,
  }
);

export const changePassword = requestHandler(
  (data: IAccountPasswordThunkData) =>
    axios.post<ILoggedInResponse>('/change-password', data),
  {
    defaultErrorMessage: errorMessages.UPDATE_PASSWORD,
    defaultSuccessMessage: successMessages.UPDATE_PASSWORD,
  }
);

export const verifyPassword = requestHandler(
  (data: IVerifyPasswordParams) =>
    axios.post<IVerifyPasswordResponse>('/verify', data),
  { defaultErrorMessage: errorMessages.VERIFY_PASSWORD }
);
