export const getAccountLink = (
  accountId: string,
  accountName: string
): string =>
  `<a href="/admin-panel/users/${accountId}" target="_blank" >${accountName}</a>`;

export const getActorLink = (actorName: string, actorId: string): string =>
  `<a href="/admin-panel/users/${actorId}" target="_blank" >${actorName}</a>`;

export const getRoomLink = (roomId: string): string =>
  `<a href="/rooms/${roomId}" target="_blank">${roomId}</a>`;

export const getClanLink = (clanId: string, clanName: string): string =>
  `<a href="/admin-panel/departments/${clanId}" target="_blank" >${clanName}</a>`;
