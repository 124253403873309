import { centroid } from '@turf/turf';
import { IClan } from 'interfaces/clan';
import { TClanResponse } from 'types/clan';

export const clanResponseAdapter = (clan: TClanResponse) => {
  const {
    id,
    name,
    location,
    work_area,
    preview_src,
    recordings_count,
    users_count,
    access_count,
    require_2fa_for_view,
  } = clan;

  const coordinates = [location.map(({ lat, lon }) => [lat, lon])];

  const [longitude, latitude] = centroid({
    geometry: { coordinates, type: 'Polygon' },
    id,
    properties: {},
    type: 'Feature',
  }).geometry.coordinates;

  const temporaryPartial: IClan = {
    id,
    name,
    coordinates,
    longitude,
    latitude,
    work_area,
    preview_src,
    players: [],
    users_count,
    access_count,
    recordings_count,
    require_2fa_for_view,
  };

  return temporaryPartial;
};
