import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useAppSelector, useBreakPoint } from 'hooks';
import { recordsSelector } from 'store/slices/records';

import { Card } from 'components/Card';
import { EmtyScreen } from 'components/EmtyScreen';
import { getWordDayForm } from 'utils';

interface IRecordsByDepartmentListProps {
  id: string;
  clan: string;
  place: string;
}

export const RecordsByDepartmentList: FC<IRecordsByDepartmentListProps> = ({
  id,
  clan,
  place,
}) => {
  const { records } = useAppSelector(recordsSelector);

  const navigate = useNavigate();

  const breakPoint = useBreakPoint();

  const recordById = records.get(id) || [];

  const handleNavigateClan = () => {
    navigate(`/records/department/${id}`);
  };

  const recordList = recordById.slice(0, 2);

  const wordForm = getWordDayForm(recordById.length, [
    'запись',
    'записи',
    'записей',
  ]);

  return (
    <div
      className="flex flex-col p-[16px] gap-[12px] bg-dark rounded-[10px] cursor-pointer hover:bg-light"
      onClick={handleNavigateClan}
    >
      <div className="flex justify-between">
        <span className="tpg-b1">{clan}</span>
        {!!recordById.length && (
          <span className="tpg-b2 text-tpg_base">
            {recordById.length} {wordForm}
          </span>
        )}
      </div>
      {!recordById.length && (
        <EmtyScreen text="Нет ни одной записи" className="!h-[370px]" />
      )}
      {!!recordList.length && (
        <div
          className={cn('grid grid-cols-2 gap-[4px] w-full', {
            '!grid-cols-1': breakPoint === 'mobile' || breakPoint === 'tablet',
          })}
        >
          {recordList.map(
            ({
              id: recordId,
              previewSrc,
              publisherName,
              publisherId,
              tags,
              startedAt,
              endedAt,
              description,
            }) => (
              <Card
                type="records"
                key={recordId}
                cardId={recordId}
                tags={tags}
                clanId={id}
                publisherName={publisherName}
                publisherId={publisherId}
                src={previewSrc}
                startedAt={startedAt}
                endedAt={endedAt}
                description={description}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};
