import { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'clsx';
import { ITag } from 'interfaces';

import { Tag } from 'components/ui/Tag';

import { MoreButton } from './MoreButton';

interface ITagsSectionProps {
  tags: ITag[];
  selectedTags?: ITag[];
  onChange?: (value: ITag[]) => void;
  classNameTag?: string;
  textButton?: string;
}

const MORE_BUTTON_WIDTH = 100;

const INDENT_DISTANCE = 5;

export const TagsSection: FC<ITagsSectionProps> = ({
  tags,
  selectedTags,
  onChange,
  classNameTag,
  textButton,
}) => {
  const [visibleTags, setVisibleTags] = useState<ITag[]>([]);
  const [isOpenTags, setOpenTags] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const tagRefs = useRef<Record<ITag['id'], HTMLDivElement | null>>({});

  const hiddenCount = useMemo(
    () => tags.length - visibleTags.length,
    [tags, visibleTags]
  );

  useEffect(() => {
    const updateTags = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;

        let totalWidth = 0;
        const displayedTags: ITag[] = [];

        tags.forEach((tag) => {
          const tagWidth = tagRefs.current[tag.id]?.offsetWidth || 0;

          const containerWidthWithTags =
            containerWidth +
            (tags.length - displayedTags.length > 1
              ? -MORE_BUTTON_WIDTH
              : INDENT_DISTANCE);

          if (totalWidth + tagWidth <= containerWidthWithTags) {
            totalWidth += tagWidth + INDENT_DISTANCE;

            displayedTags.push(tag);
          }
        });

        setVisibleTags(displayedTags);
      }
    };

    updateTags();

    window.addEventListener('resize', updateTags);

    return () => window.removeEventListener('resize', updateTags);
  }, [tags, containerRef, tagRefs]);

  const handleTagSelectionClick = (tag: ITag) => {
    if (selectedTags && onChange) {
      const newValue = selectedTags.find((el) => el.id === tag.id)
        ? selectedTags.filter((el) => el.id !== tag.id)
        : [...selectedTags, tag];

      onChange(newValue);
    }
  };

  const isTagSelected = (tag: ITag) =>
    selectedTags?.find((el) => el.id === tag.id);

  return (
    <div className="relative">
      <div
        ref={containerRef}
        className={cn(
          'flex flex-row flex-wrap w-full gap-y-[8px] gap-x-[4px]',
          !isOpenTags && 'invisible absolute top-0 z-[0]'
        )}
      >
        {tags.map(
          (tag) =>
            tag.id && (
              <Tag
                key={tag.id}
                ref={(el) => {
                  if (tagRefs?.current) {
                    tagRefs.current[tag.id] = el;
                  }
                }}
                className={cn({
                  'bg-dark_product hover:!bg-dark_product': isTagSelected(tag),
                })}
                label={tag.title}
                onClick={() => handleTagSelectionClick(tag)}
              />
            )
        )}
        {!!hiddenCount && isOpenTags && (
          <MoreButton onClick={() => setOpenTags(!isOpenTags)} />
        )}
      </div>
      <div
        className={cn(
          'flex flex-row gap-y-[8px] gap-x-[4px] z-[1]',
          isOpenTags && 'invisible absolute top-0 !z-[0]'
        )}
      >
        {visibleTags.map(
          (tag) =>
            tag.id && (
              <Tag
                key={tag.id}
                className={cn(
                  {
                    'bg-dark_product hover:!bg-dark_product':
                      isTagSelected(tag),
                  },
                  classNameTag
                )}
                label={tag.title}
                onClick={() => handleTagSelectionClick(tag)}
              />
            )
        )}
        {!!hiddenCount && (
          <MoreButton
            count={hiddenCount}
            text={textButton}
            onClick={() => setOpenTags(!isOpenTags)}
          />
        )}
      </div>
    </div>
  );
};
