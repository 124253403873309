import { useEffect, useState } from 'react';

import { getRoomStats } from '../../api/roomStats';
import { LP_INTERVAL_MS } from '../../constants/profile';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IListRoomStats } from '../../interfaces';
import { clansSelector } from '../../store';
import { getClansThunk } from '../../store/slices/clan/actions';

import { RoomStat } from './RoomStat';

export const RoomStatsScreen = () => {
  const [roomStats, setRoomStats] = useState<IListRoomStats[]>([]);
  const dispatch = useAppDispatch();

  const { clans } = useAppSelector(clansSelector);

  const loadRoomStatsAndClans = async () => {
    dispatch(getClansThunk());
    const { error, data } = await getRoomStats();

    if (error) {
      setRoomStats([]);
    } else if (data) {
      setRoomStats(data);
    }
  };

  useEffect(() => {
    loadRoomStatsAndClans();
    const roomsInterval = setInterval(loadRoomStatsAndClans, LP_INTERVAL_MS);

    return () => {
      clearInterval(roomsInterval);
    };
  }, []);

  return (
    <div className="flex flex-col gap-[16px]">
      {roomStats.map((roomStats, idx) => (
        <RoomStat roomStats={roomStats} idx={idx} key={idx} clans={clans} />
      ))}
    </div>
  );
};
