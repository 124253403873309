import { errorMessages, successMessages } from '../constants/messages';
import { IListResponse } from '../interfaces/audit';
import { IListParams } from '../interfaces/player';
import { ISession } from '../interfaces/sessions';
import axios from '../services/axios';

import { requestHandler } from './utils/requestHandler';

export const getAccountSessions = requestHandler(
  (accountI?: string) => axios.get<ISession[]>(`/sessions/${accountI}`),
  { defaultErrorMessage: errorMessages.GET_ACCOUNT_SESSIONS }
);

export const getSessions = requestHandler(
  (params?: IListParams) =>
    axios.post<IListResponse<ISession>>('/sessions', params),
  { defaultErrorMessage: errorMessages.GET_ACCOUNT_SESSIONS }
);

export const deactivateSession = requestHandler(
  (sessionId: string) =>
    axios.delete<IListResponse<ISession>>(`/sessions/${sessionId}`),
  {
    defaultErrorMessage: errorMessages.DEACTIVATE_SESSION,
    defaultSuccessMessage: successMessages.DEACTIVATE_SESSION,
  }
);
