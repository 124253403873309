import { useParams } from 'react-router-dom';

import { RecordInfo } from './RecordInfo';

export const RecordScreen = () => {
  const { id: recordId } = useParams();

  return (
    <div className="flex flex-col gap-[16px] w-[calc(100%+48px)] m-[-24px] p-[8px]">
      {recordId ? (
        <RecordInfo recordId={recordId} />
      ) : (
        <h2>Запись не найдена</h2>
      )}
    </div>
  );
};
