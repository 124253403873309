import { useMemo, useRef } from 'react';
import { IRoomParams } from 'interfaces';
import { roomSelector } from 'store/slices/room';

import { useAppSelector } from './redux';
import { useBreakPoint } from './useBreakPoint';

const GAP = 5;

export const useStreamHeight = (rooms: IRoomParams[], screenHeight: number) => {
  const streamHeight = useRef(0);

  const { rowCount } = useAppSelector(roomSelector);
  const breakPoint = useBreakPoint();

  const count = useMemo(
    () =>
      ((breakPoint === 'mobile' || breakPoint === 'tablet') && 1) ||
      (rooms.length <= 2 && 1) ||
      (rooms.length >= 3 && rooms.length <= 6 && 2) ||
      (rooms.length >= 7 && rooms.length <= 12 && 3) ||
      (rooms.length >= 13 && rooms.length <= 20 && 4) ||
      5,
    [breakPoint, rooms]
  );

  const totalCount = useMemo(() => {
    if (rowCount === 'auto') return count;

    return breakPoint === 'mobile' || breakPoint === 'tablet'
      ? 1
      : Number(rowCount);
  }, [count, rowCount, breakPoint]);

  streamHeight.current = useMemo(
    () => (screenHeight ? screenHeight / totalCount - GAP : 0),
    [screenHeight, totalCount]
  );

  return streamHeight.current;
};
