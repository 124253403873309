import React, { RefObject, useState } from 'react';
import { format } from 'date-fns';
import { ReactComponent as DateActiveFilterIcon } from 'images/newIcons/calendarActive.svg';
import { ReactComponent as DateFilterIcon } from 'images/newIcons/calendarPassive.svg';
import { ReactComponent as Close } from 'images/newIcons/close.svg';

import DatePicker from '../DatePicker';
import { IFilterValue } from '../Table/STableHeader';

import BaseFilter from './BaseFilter';

import 'react-datepicker/dist/react-datepicker.css';

interface DateFilterProps {
  field: string;
  onFilterAdd: (field: string, value: string) => void;
  resetFilters: (field: string) => void;
  selectedFilters?: IFilterValue[];
  scrollContainerRef: RefObject<HTMLDivElement>;
  tableCellRef?: RefObject<HTMLTableCellElement>;
  containerId?: string;
}

const DateFilter: React.FC<DateFilterProps> = ({
  field,
  onFilterAdd,
  resetFilters,
  selectedFilters,
  scrollContainerRef,
  tableCellRef,
  containerId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (selectedFilters?.find((el) => el.id === field)?.values) {
      resetFilters(field);
    }

    if (start && end) {
      const formatedStart = format(start, 'dd.MM.yyyy');
      const formatedEnd = format(end, 'dd.MM.yyyy');

      if (formatedStart === formatedEnd) {
        onFilterAdd(field, formatedStart);
      } else {
        onFilterAdd(field, formatedStart);
        onFilterAdd(field, formatedEnd);
      }
    }
  };

  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    resetFilters(field);
  };

  const renderDropdownContent = () => (
    <div className="flex flex-col">
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        dateFormat="yyyy-MM-dd"
        placeholderText="Выберите диапазон"
        inline
      />
      <div className="flex justify-end items-center px-3 py-1 tpg-c2 cursor-default">
        <div
          className="flex flex-row items-center gap-1 cursor-pointer"
          onClick={handleClearFilters}
        >
          <span className="text-tpg_base">Сбросить</span>
          <Close className="icon-container" />
        </div>
      </div>
    </div>
  );

  const filterIcon = (
    <div className="text-gray-700">
      <DateFilterIcon width={16} height={16} fill={'#20242E'} />{' '}
    </div>
  );

  const filterActiveIcon = <DateActiveFilterIcon width={16} height={16} />;

  return (
    <div className="absolute">
      <BaseFilter
        isOpen={isOpen}
        isActive={!!startDate || !!endDate}
        setIsOpen={setIsOpen}
        dropdownContent={renderDropdownContent()}
        scrollContainerRef={scrollContainerRef}
        tableCellRef={tableCellRef}
        containerId={containerId}
        icon={filterIcon}
        activeIcon={filterActiveIcon}
      />
    </div>
  );
};

export default DateFilter;
