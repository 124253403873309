import { FC, useState } from 'react';
import { useAppDispatch } from 'hooks';
import { updateMfaRequiredThunk } from 'store';

import { Switch } from 'components/ui';

interface TwoFactorSwitchProps {
  id: string;
  mfaRequired: boolean;
  disabled: boolean;
}

export const TwoFactorSwitch: FC<TwoFactorSwitchProps> = ({
  id,
  mfaRequired,
  disabled,
}) => {
  const [isMfaRequired, setMfaRequired] = useState(mfaRequired);

  const dispatch = useAppDispatch();

  const handleTwoFactorChange = (id: string, isMfaRequired: boolean) => {
    dispatch(
      updateMfaRequiredThunk({
        account_id: id,
        mfa_required: !isMfaRequired,
      })
    ).then(() => {
      setMfaRequired((prev) => !prev);
    });
  };

  return (
    <Switch
      checked={isMfaRequired}
      disabled={disabled}
      onChange={() => handleTwoFactorChange(id, isMfaRequired)}
    />
  );
};
