import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';

import { Switch } from 'components/ui';

export const ToggleListView = () => {
  const [isListView, setListView] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const breakPoint = useBreakPoint();

  const search = searchParams.get('allRecords');

  useEffect(() => {
    setListView(search === 'true' ? true : false);
  }, [search]);

  const handleListViewChange = () => {
    setListView(!isListView);

    setSearchParams({
      ...Object.fromEntries(searchParams),
      allRecords: `${!isListView}`,
    });
  };

  const isMobile = breakPoint === 'mobile';

  return (
    <div className="flex flex-row gap-[8px]">
      <span className={cn('tpg-c2', { '!text-tpg_base': isMobile })}>
        В одной ленте
      </span>
      <Switch checked={isListView} onChange={handleListViewChange} />
    </div>
  );
};
