import { ELStorageKeys } from 'constants/localStorage';
import { useAppDispatch, useAppSelector } from 'hooks';
import { roomActions, roomSelector } from 'store/slices/room';

import { setLStorage } from 'utils/helpers/localStorage';

export const ToggleFullscreenMode = () => {
  const { fullscreenMode } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  const handleFullscreenModeClick = () => {
    if (fullscreenMode === 'off') {
      setLStorage([ELStorageKeys.useCustomFullscreen, 'on']);

      dispatch(roomActions.setFullscreenMode('on'));
    } else {
      setLStorage([ELStorageKeys.useCustomFullscreen, 'off']);

      dispatch(roomActions.setFullscreenMode('off'));
    }
  };

  return (
    <span
      className="cursor-pointer tpg-c1 text-tpg_base hover:text-bright_product transition"
      onClick={handleFullscreenModeClick}
    >
      {fullscreenMode === 'off'
        ? 'Полный экран в режиме окна'
        : 'Полный экран в обычном режиме'}
    </span>
  );
};
