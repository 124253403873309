import { useMemo } from 'react';
import { IRoomParams } from 'interfaces';
import { roomSelector } from 'store/slices/room/selectors';

import { useAppSelector } from './redux';
import { useBreakPoint } from './useBreakPoint';

export const useStreamsDynamicGrid = (rooms: IRoomParams[]) => {
  const { colCount } = useAppSelector(roomSelector);

  const breakPoint = useBreakPoint();

  const gridSize = useMemo(
    () =>
      ((breakPoint === 'mobile' ||
        breakPoint === 'tablet' ||
        rooms.length === 1) &&
        1) ||
      (rooms.length >= 2 && rooms.length <= 4 && 2) ||
      (((rooms.length >= 5 && rooms.length <= 9) ||
        breakPoint === 'tablet-landscape') &&
        3) ||
      (rooms.length >= 10 && rooms.length <= 16 && 4) ||
      5,
    [rooms, breakPoint]
  );

  return colCount === 'auto'
    ? gridSize
    : breakPoint === 'mobile' || breakPoint === 'tablet'
    ? 1
    : Number(colCount);
};
