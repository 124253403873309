import { useEffect, useState } from 'react';

import { eventMap$ } from 'utils/events/eventDispatcher';
import { Event, EventTypes } from 'utils/events/events';

type EventState = {
  current?: Event;
};

const initialEventState: EventState = {};

const useEvent = (name: EventTypes) => {
  const [current, setCurrent] = useState(initialEventState.current);

  useEffect(() => {
    const subscription = eventMap$.subscribe((eventData) => {
      if (eventData === undefined) {
        return setCurrent(undefined);
      }

      if (name === eventData?.name && current?.id !== eventData?.id) {
        setCurrent(eventData);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [current, name]);

  return current;
};

export default useEvent;
