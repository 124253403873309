import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clansSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';
import { recordsActions, recordsSelector } from 'store/slices/records';
import { getRecordsByClansThunk } from 'store/slices/records/actions';
import { TClanResponse } from 'types/clan';

import { RecordsList } from 'components/RecordsList';

import { RecordsByDepartmentList } from './RecordsByDepartmentList';

export const RecordsByDepartmentScreen = () => {
  const { clans } = useAppSelector(clansSelector);
  const { records } = useAppSelector(recordsSelector);

  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const allRecordsParams = searchParams?.get('allRecords');

  useEffect(() => {
    const fetchClansAndRecords = async () => {
      const res = await dispatch(getClansThunk());
      const clans = res.payload as TClanResponse[];

      if (clans.length) {
        await dispatch(
          getRecordsByClansThunk({ clanIds: clans.map((clan) => clan.id) })
        );
      }
    };

    fetchClansAndRecords();
  }, []);

  useEffect(() => {
    if (!allRecordsParams || allRecordsParams === 'false') {
      dispatch(recordsActions.resetFilterParams());
    }
  }, [allRecordsParams]);

  const allRecords = Array.from(records.values()).flatMap((obj) =>
    Object.values(obj)
  );

  return (
    <div className="flex flex-col gap-[8px] w-[calc(100%+48px)] h-full m-[-24px] p-[8px]">
      {(!allRecordsParams || allRecordsParams === 'false') &&
        clans.map(({ id, name, work_area }) => (
          <RecordsByDepartmentList
            key={id}
            clan={name}
            id={id}
            place={work_area}
          />
        ))}
      {allRecordsParams === 'true' && <RecordsList records={allRecords} />}
    </div>
  );
};
