import { IRoomsSearchParams, ITreeRooms } from 'interfaces';
import { ESearchParamsStream } from 'interfaces/stream';

export const updateHiddenRoomsParams = (
  treeRooms: ITreeRooms[],
  searchParams: URLSearchParams,
  updatedTreeRooms?: ITreeRooms[]
) => {
  const showClansParams = searchParams.get(ESearchParamsStream.ShowClans);

  const showAllClansParams = searchParams.get(ESearchParamsStream.ShowAllClans);

  const hideAllClansParams = searchParams.get(ESearchParamsStream.HideAllClans);

  const currentParams: IRoomsSearchParams[] = showClansParams
    ? JSON.parse(showClansParams)
    : [];

  const unhiddenTreeRooms = updatedTreeRooms?.filter(
    ({ isHidden, rooms }) => rooms.some((room) => !room.isHidden) || !isHidden
  );

  if (unhiddenTreeRooms?.length) {
    const convertedParams = unhiddenTreeRooms.map(
      ({ clanId, rooms, isHidden }) => {
        const hiddenRooms = rooms.flatMap((room) =>
          room.isHidden ? room.livekitName : []
        );

        const showRooms = rooms.flatMap((room) =>
          !room.isHidden ? room.livekitName : []
        );

        if (showAllClansParams) {
          return { clanId, hiddenRooms };
        }

        const params = currentParams.find((param) => param.clanId === clanId);

        if (!params) {
          if (!showRooms.length) {
            return { clanId };
          }

          if (!isHidden) {
            return hiddenRooms.length ? { clanId, hiddenRooms } : { clanId };
          }

          return { clanId, showRooms };
        }

        if (params.hiddenRooms?.length) {
          return hiddenRooms.length ? { clanId, hiddenRooms } : { clanId };
        }

        if (params.showRooms) {
          return { clanId, showRooms };
        }

        return hiddenRooms.length ? { clanId, hiddenRooms } : { clanId };
      }
    );

    const hiddenRooms = convertedParams.filter((param) => param.hiddenRooms);
    const showRooms = convertedParams.filter((param) => param.showRooms);

    if (
      unhiddenTreeRooms?.length === treeRooms.length &&
      !hiddenRooms.length &&
      !showRooms.length
    ) {
      searchParams.delete(ESearchParamsStream.ShowClans);
      searchParams.delete(ESearchParamsStream.HideAllClans);

      return {
        ...Object.fromEntries(searchParams),
        [ESearchParamsStream.ShowAllClans]: 'true',
      };
    }

    if (!currentParams.length) {
      searchParams.delete(ESearchParamsStream.ShowAllClans);
      searchParams.delete(ESearchParamsStream.HideAllClans);

      return {
        ...Object.fromEntries(searchParams),
        [ESearchParamsStream.ShowClans]: JSON.stringify(convertedParams),
      };
    }

    return {
      ...Object.fromEntries(searchParams),
      [ESearchParamsStream.ShowClans]: JSON.stringify(convertedParams),
    };
  }

  if (!showAllClansParams && !showClansParams && !hideAllClansParams) {
    return {
      ...Object.fromEntries(searchParams),
      [ESearchParamsStream.ShowAllClans]: JSON.stringify(true),
    };
  }

  searchParams.delete(ESearchParamsStream.ShowClans);
  searchParams.delete(ESearchParamsStream.ShowAllClans);

  return {
    ...Object.fromEntries(searchParams),
    [ESearchParamsStream.HideAllClans]: JSON.stringify(true),
  };
};

export const updateHiddenClansParams = (
  searchParams: URLSearchParams,
  clanId: string,
  treeRooms: ITreeRooms[],
  currentRooms: ITreeRooms[],
  isHidden?: boolean
) => {
  const showClansParams = searchParams.get(ESearchParamsStream.ShowClans);

  const showAllClans = searchParams.get(ESearchParamsStream.ShowAllClans);

  const currentParams: IRoomsSearchParams[] = showClansParams
    ? JSON.parse(showClansParams)
    : [];

  if (currentParams.length) {
    const filteredParamsByClanId = currentParams.find(
      (param) => param.clanId === clanId
    );

    if (filteredParamsByClanId) {
      const newParams = currentParams.flatMap((param) => {
        if (param.clanId !== clanId) return param;

        if (isHidden || !param.showRooms) return [];

        return { clanId };
      });

      const filteredParamsByHiddenRooms = newParams.filter(
        (clan) => clan.hiddenRooms
      );

      const filteredParamsByShowRooms = newParams.filter(
        (clan) => clan.showRooms
      );

      if (
        newParams.length === treeRooms.length &&
        !filteredParamsByHiddenRooms.length &&
        !filteredParamsByShowRooms.length
      ) {
        searchParams.delete(ESearchParamsStream.ShowClans);
        searchParams.delete(ESearchParamsStream.HideAllClans);

        return {
          ...Object.fromEntries(searchParams),
          [ESearchParamsStream.ShowAllClans]: 'true',
        };
      }

      if (!newParams.length) {
        searchParams.delete(ESearchParamsStream.ShowClans);
        searchParams.delete(ESearchParamsStream.ShowAllClans);

        return {
          ...Object.fromEntries(searchParams),
          [ESearchParamsStream.HideAllClans]: JSON.stringify(true),
        };
      }

      searchParams.delete(ESearchParamsStream.HideAllClans);

      return {
        ...Object.fromEntries(searchParams),
        [ESearchParamsStream.ShowClans]: JSON.stringify(newParams),
      };
    } else {
      const newParams = [...currentParams, { clanId }];

      const filteredParamsByHiddenRooms = newParams.filter(
        (clan) => clan.hiddenRooms
      );

      const filteredParamsByShowRooms = newParams.filter(
        (clan) => clan.showRooms
      );

      if (
        newParams.length === treeRooms.length &&
        !filteredParamsByHiddenRooms.length &&
        !filteredParamsByShowRooms.length
      ) {
        searchParams.delete(ESearchParamsStream.ShowClans);
        searchParams.delete(ESearchParamsStream.HideAllClans);

        return {
          ...Object.fromEntries(searchParams),
          [ESearchParamsStream.ShowAllClans]: 'true',
        };
      }

      searchParams.delete(ESearchParamsStream.HideAllClans);

      return {
        ...Object.fromEntries(searchParams),
        [ESearchParamsStream.ShowClans]: JSON.stringify(newParams),
      };
    }
  }

  if (currentRooms.length) {
    const convertedClans = currentRooms.flatMap((room) =>
      room.clanId !== clanId ? { clanId: room.clanId } : []
    );

    if (showAllClans) {
      if (!convertedClans.length) {
        searchParams.delete(ESearchParamsStream.ShowAllClans);

        return {
          ...Object.fromEntries(searchParams),
          [ESearchParamsStream.HideAllClans]: 'true',
        };
      }

      searchParams.delete(ESearchParamsStream.ShowAllClans);
      searchParams.delete(ESearchParamsStream.HideAllClans);

      return {
        ...Object.fromEntries(searchParams),
        [ESearchParamsStream.ShowClans]: JSON.stringify(convertedClans),
      };
    }

    searchParams.delete(ESearchParamsStream.HideAllClans);

    return {
      ...Object.fromEntries(searchParams),
      [ESearchParamsStream.ShowClans]: JSON.stringify([
        ...currentParams,
        { clanId },
      ]),
    };
  }

  searchParams.delete(ESearchParamsStream.ShowClans);
  searchParams.delete(ESearchParamsStream.HideAllClans);

  return {
    ...Object.fromEntries(searchParams),
    [ESearchParamsStream.ShowAllClans]: 'true',
  };
};

export const updateSearchParams = (
  currentParams: URLSearchParams,
  showClans: IRoomsSearchParams[],
  showAllStreams: boolean,
  showAllOtherStreams: boolean
) => {
  const hiddenRooms = showClans.filter((clan) => clan.hiddenRooms?.length);

  if (showAllStreams && !showAllOtherStreams && !hiddenRooms.length) {
    currentParams.delete(ESearchParamsStream.ShowClans);
    currentParams.delete(ESearchParamsStream.HideAllClans);

    return {
      ...Object.fromEntries(currentParams),
      [ESearchParamsStream.ShowAllClans]: JSON.stringify(true),
    };
  }

  if (!showAllStreams && !showAllOtherStreams) {
    currentParams.delete(ESearchParamsStream.ShowAllClans);
    currentParams.delete(ESearchParamsStream.HideAllClans);

    if (showClans.length) {
      return {
        ...Object.fromEntries(currentParams),
        [ESearchParamsStream.ShowClans]: JSON.stringify(showClans),
      };
    } else {
      currentParams.delete(ESearchParamsStream.ShowClans);

      return {
        ...Object.fromEntries(currentParams),
        [ESearchParamsStream.HideAllClans]: 'true',
      };
    }
  }

  if (showAllStreams && showAllOtherStreams) {
    currentParams.delete(ESearchParamsStream.HideAllClans);

    return {
      ...Object.fromEntries(currentParams),
      [ESearchParamsStream.ShowClans]: JSON.stringify(showClans),
    };
  }

  if (showClans.length) {
    currentParams.delete(ESearchParamsStream.HideAllClans);

    return {
      ...Object.fromEntries(currentParams),
      [ESearchParamsStream.ShowClans]: JSON.stringify(showClans),
    };
  }

  currentParams.delete(ESearchParamsStream.ShowClans);
  currentParams.delete(ESearchParamsStream.ShowAllClans);

  return {
    ...Object.fromEntries(currentParams),
    [ESearchParamsStream.HideAllClans]: 'true',
  };
};

export const setPinnedRoomsInSearchParams = (
  searchParams: URLSearchParams,
  treeRooms: ITreeRooms[]
) => {
  const params = treeRooms.flatMap(({ isPinned, clanId, rooms }) => {
    const result = isPinned ? [{ clanId }] : [];

    const pinnedRooms = rooms.flatMap((room) => {
      if (room.isPinned) {
        return room.publisherName;
      }

      return [];
    });

    return pinnedRooms.length
      ? [...result, { clanId, rooms: pinnedRooms }]
      : result;
  });

  if (params.length) {
    return {
      ...Object.fromEntries(searchParams),
      [ESearchParamsStream.Pinned]: JSON.stringify(params),
    };
  } else {
    searchParams.delete(ESearchParamsStream.Pinned);

    return searchParams;
  }
};
