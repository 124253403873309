import { FC } from 'react';
import { ReactComponent as MarkerIcon } from 'images/newIcons/marker.svg';

import eventDispatcher from '../../../utils/events/eventDispatcher';
import { EventTypes } from '../../../utils/events/events';

export const NavigatorScreenControlPanel: FC = () => {
  const handleUpdateLocation = () => {
    eventDispatcher.dispatch({
      name: EventTypes.NAVIGATOR_MAP_LOCATION_UPDATED,
    });
  };

  return (
    <div
      className="group flex flex-row gap-1 items-center cursor-pointer"
      onClick={handleUpdateLocation}
    >
      <span className="tpg-c2 text-center group-hover:text-bright_product transition relative top-[1px]">
        дрон здесь
      </span>
      <MarkerIcon className="[&>path]:fill-tpg_title group-hover:[&>path]:fill-bright_product [&>path]:transition" />
    </div>
  );
};
