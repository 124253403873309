import { FC, useState } from 'react';

import { RadioGroup, TextInput } from 'components/ui';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

import { createNotification } from '../../api/notifications';
import { NotificationType } from '../../constants/profile';
import { IPlayerNotification } from '../../interfaces/player';
import DatePicker from '../ui/DatePicker';

interface CreateNotificationModalProps {
  onClose: () => void;
}

const typeOptionsList = [
  {
    label: 'С кнопкой обновления страницы',
    value: NotificationType.REFRESH,
  },
  {
    label: 'Без кнопки обновления страницы',
    value: NotificationType.INFO,
  },
];

export const CreateNotificationModal: FC<CreateNotificationModalProps> = ({
  onClose,
}) => {
  const [info, setInfo] = useState<string>('');
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [notificationType, setNotificationType] = useState<NotificationType>(
    NotificationType.INFO
  );

  const handleSubmit = async () => {
    const createNotificationParams: Omit<IPlayerNotification, 'id'> = {
      info,
      type: notificationType,
      start: new Date().toISOString(),
      end: endDate ? endDate.toISOString() : new Date().toISOString(),
    };

    await createNotification(createNotificationParams);
    onClose();
  };

  const handleTypeChange = (type: NotificationType) => {
    setNotificationType(type);
  };

  const handleTimestampChange = (date: Date) => {
    setEndDate(date);
  };

  return (
    <Modal onClose={onClose} isBlurred>
      <div className="flex flex-col gap-[24px] w-[460px] justify-center border border-solid border-tpg_light rounded-[10px] bg-dark p-[48px]">
        <span className="tpg-h4 text-center">
          Создать уведомление о тех. работах
        </span>
        <div className="flex flex-col gap-[12px]">
          <TextInput
            placeholder="Введите описание"
            inputClassName="bg-ultrablack"
            onChange={(value) => setInfo(value)}
            value={info}
          />
          <RadioGroup<NotificationType>
            options={typeOptionsList}
            currentId={notificationType}
            onChange={handleTypeChange}
          />
          <div className="datepicker-label tpg-body-one">
            Дата окончания
            <DatePicker
              minDate={new Date()}
              selected={endDate}
              endDate={endDate}
              onChange={handleTimestampChange}
            />
          </div>
        </div>
        <Button
          title="Создать"
          className="w-full h-[40px]"
          disabled={!endDate || info.length === 0}
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  );
};
