import { errorMessages, successMessages } from '../constants/messages';
import { IPlayerNotification } from '../interfaces/player';
import axios from '../services/axios';

import { requestHandler } from './utils/requestHandler';

export const createNotification = requestHandler(
  (request: Omit<IPlayerNotification, 'id'>) =>
    axios.post<void>('/notifications', request),
  {
    defaultErrorMessage: errorMessages.CREATE_NOTIFICATION,
    defaultSuccessMessage: successMessages.CREATE_NOTIFICATION,
  }
);
