import { FC, MouseEvent, useCallback, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LiveKitRoom } from '@livekit/components-react';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useClickOutside } from 'hooks';
import { Track } from 'livekit-client';
import { authSelector } from 'store/slices/auth/selectors';
import { roomActions, roomSelector } from 'store/slices/room';
import {
  deleteRoomThunk,
  getAllLiveKitTokensThunk,
} from 'store/slices/room/actions';
import { isRoot, rolesValuesMap } from 'types/player';

import { updateHiddenRoomsParams } from 'components/FilterStreams/utils';
import { ViewerPlayer } from 'components/StreamPlayerContainer/components/ViewerPlayer';
import { Button } from 'components/ui';
import { Loader } from 'components/ui/Loader';
import { convertRoomNameToParams, roomHideController } from 'utils';

interface RoomProps {
  livekitName: string;
  publisherName: string;
  roomName: string;
  accountId: string;
  quality: Track.Dimensions;
  clanId?: string;
  clanName?: string;
  token?: string;
  playerClassName?: string;
  height?: number;
  isMain?: boolean;
  isMainBlock?: boolean;
  isShowSwitchButtonMain?: boolean;
  onSelectMainRoom?: (name: string) => void;
}

export const Room: FC<RoomProps> = ({
  livekitName,
  publisherName,
  roomName,
  accountId,
  quality,
  clanName,
  clanId,
  token,
  playerClassName,
  height,
  isMain,
  isMainBlock,
  isShowSwitchButtonMain,
  onSelectMainRoom,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  const [connectKey, setConnectKey] = useState(0);

  const [isShowButtons, setShowButtons] = useState(false);

  const { id, roles } = useAppSelector(authSelector);

  const { treeRooms } = useAppSelector(roomSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowButtons(false)
  );

  const highPriorityRoles = roles.filter(
    (role) =>
      role.role_name === EUserRole.ADMIN || role.role_name === EUserRole.ROOT
  );

  const isAdminForPlayer =
    (!!highPriorityRoles.filter((role) => role.clan_id === clanId).length &&
      accountId !== rolesValuesMap.root) ||
    isRoot(id);

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();

    setShowButtons(true);
  };

  const handleHideRoom = (livekitName: string) => {
    if (clanId) {
      const newTreeRooms = roomHideController(treeRooms, clanId, livekitName);

      dispatch(roomActions.updateTreeRooms(newTreeRooms));

      const newParams = updateHiddenRoomsParams(
        treeRooms,
        searchParams,
        newTreeRooms
      );

      setSearchParams(newParams);
    }
  };

  const handleDeleteRoom = () => {
    if (clanId) {
      dispatch(
        deleteRoomThunk({
          clanId,
          roomName,
          params: { save_recording: false },
        })
      );
    }
  };

  const handleReconnect = (e: MouseEvent, livekitName: string) => {
    e.stopPropagation();

    const { clan_id, room_name } = convertRoomNameToParams(livekitName);

    dispatch(getAllLiveKitTokensThunk([{ clan_id, room_name }]));

    setShowButtons(false);

    setLoading(true);
    setError(false);
    setConnectKey((prevKey) => prevKey + 1);
  };

  const handleRoomError = useCallback(() => {
    setLoading(false);
    setError(true);
  }, []);

  const handleRoomConnected = useCallback(() => {
    setLoading(false);
    setError(false);
  }, []);

  return (
    <div
      ref={containerRef}
      className="relative border-solid border-tpg_light border-[1px] rounded-[10px] bg-light"
      style={{ height: height ? `${height}px` : '100%' }}
      onContextMenu={handleContextMenu}
    >
      {isLoading && (
        <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center z-[1]">
          <Loader />
        </div>
      )}
      {isError && (
        <div className="h-full w-full absolute top-0 left-0 flex flex-col gap-[14px] justify-center items-center z-[1]">
          <Button
            onClick={(e) => handleReconnect(e, livekitName)}
            title="Обновить"
            className="!h-[24px]"
          />
          <span className="tpg-c2 text-error">Данные не загрузились</span>
        </div>
      )}
      <LiveKitRoom
        key={connectKey}
        token={token}
        onError={handleRoomError}
        onConnected={handleRoomConnected}
        serverUrl={process.env.REACT_APP_LIVEKIT_API}
        className="h-full"
      >
        <ViewerPlayer
          livekitName={livekitName}
          isMuted={true}
          publisherName={publisherName}
          clanName={clanName}
          className={playerClassName}
          isMain={isMain}
          isMainBlock={isMainBlock}
          quality={quality}
          isShowSwitchButtonMain={isShowSwitchButtonMain}
          onSelectMainRoom={onSelectMainRoom}
        />
      </LiveKitRoom>
      {isShowButtons && (
        <div className="absolute z-[1] left-[50%] top-[50%] py-[6px] translate-x-[-50%] translate-y-[-50%] max-w-[133px] right-0 flex flex-col rounded-[10px] bg-ultrablack border border-solid border-tpg_light">
          <span
            className="cursor-pointer tpg-c2 px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
            onClick={(e) => handleReconnect(e, livekitName)}
          >
            Перезагрузить
          </span>
          {(!isMainBlock || (isMainBlock && isMain)) && (
            <div className="flex flex-col">
              {!isMainBlock && (
                <span
                  className="cursor-pointer tpg-c2 px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
                  onClick={() => handleHideRoom(livekitName)}
                >
                  Скрыть
                </span>
              )}
              {isAdminForPlayer && (
                <span
                  className="cursor-pointer tpg-c2 px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
                  onClick={handleDeleteRoom}
                >
                  Завершить
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
