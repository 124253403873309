import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatTimestamp } from 'api/utils/timeUtils';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getClansThunk } from 'store/slices/clan/actions';
import { recordsActions, recordsSelector } from 'store/slices/records';
import { getRecordsByClansThunk } from 'store/slices/records/actions';
import { TClanResponse } from 'types/clan';

import { Navigation } from 'components/Navigation';

interface IActiveRecord {
  index: number;
  date: string;
}

export const RecordNavigation = () => {
  const [activeRecord, setActiveRecord] = useState<IActiveRecord>();

  const { records, selectedRecord } = useAppSelector(recordsSelector);

  const dispatch = useAppDispatch();

  const { id } = useParams();

  const navigate = useNavigate();

  const allRecords = useMemo(() => {
    if (!selectedRecord?.clanId) return [];

    return records.get(selectedRecord.clanId) || [];
  }, [selectedRecord?.clanId, records]);

  useEffect(() => {
    const fetchClansAndRecords = async () => {
      const res = await dispatch(getClansThunk());
      const clans = res.payload as TClanResponse[];

      if (clans.length) {
        await dispatch(
          getRecordsByClansThunk({ clanIds: clans.map((clan) => clan.id) })
        );
      }
    };

    fetchClansAndRecords();
  }, []);

  useEffect(() => {
    if (id) {
      const index = allRecords.findIndex((record) => record.id === id);

      if (index !== -1) {
        setActiveRecord({ index, date: allRecords[index].startedAt });
      }
    }
  }, [allRecords, id]);

  const handleClickArrow = (index: number) => {
    dispatch(recordsActions.resetFilterParams());

    if (allRecords) {
      const records = allRecords[index];

      if (records) {
        navigate(`/records/${records.id}`);

        setActiveRecord({ index, date: records.startedAt });
      }
    }
  };

  if (allRecords.length <= 1 || !activeRecord) return null;

  return (
    <Navigation
      text={formatTimestamp(activeRecord.date)}
      onClickRight={() => handleClickArrow(activeRecord.index + 1)}
      onClickLeft={() => handleClickArrow(activeRecord.index - 1)}
      isDisableRightButton={activeRecord.index + 1 === allRecords.length}
      isDisableLeftButton={activeRecord.index === 0}
    />
  );
};
