import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from '../../constants/routes';
import { useBreakPoint } from '../../hooks';
import useEvent from '../../hooks/useEvent';
import eventDispatcher from '../../utils/events/eventDispatcher';
import { Event, EventTypes } from '../../utils/events/events';
import { AddButton } from '../AddButton';

export const UserTableHeaderRightContent = () => {
  const [selectedRows, setSelectedRows] = useState(0);

  const navigate = useNavigate();
  const breakPoint = useBreakPoint();

  const commonButtonClassName =
    breakPoint === 'mobile'
      ? 'text-tpg_base !justify-start'
      : 'flex-row-reverse';

  const tableSelectionChangedEvent: Event | undefined = useEvent(
    EventTypes.TABLE_SELECTION_CHANGED
  );

  useEffect(() => {
    if (
      tableSelectionChangedEvent?.name === EventTypes.TABLE_SELECTION_CHANGED
    ) {
      const selectedAmount = tableSelectionChangedEvent.data.selectedAmount;

      setSelectedRows(selectedAmount);
    }
  }, [tableSelectionChangedEvent]);

  return (
    <div className="flex flex-row gap-3">
      {selectedRows > 0 ? (
        <>
          <div
            onClick={() =>
              eventDispatcher.dispatch({
                name: EventTypes.RESET_TABLE_SELECTION,
              })
            }
            className="tpg-c2 cursor-pointer hover:text-bright_product transition"
          >
            <span className="tpg-c2">Сбросить</span>
          </div>
          <div className="tpg-c2">
            <span className="header-link__title tpg-c2">
              Выбрано: {selectedRows}
            </span>
          </div>
          <AddButton
            label="Добавить доступ"
            onClick={() =>
              eventDispatcher.dispatch({
                name: EventTypes.SET_CLAN_MEMBERS_BULK_BUTTON_CLICKED,
              })
            }
            className={commonButtonClassName}
            isShowIcon={breakPoint !== 'mobile'}
          />
        </>
      ) : (
        <AddButton
          label="Добавить пользователя"
          onClick={() => navigate(`${appRoutes.ADMIN_NEW_USER}`)}
          className={commonButtonClassName}
          isShowIcon={breakPoint !== 'mobile'}
        />
      )}
    </div>
  );
};
