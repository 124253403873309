import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { TextInput } from 'components/ui';

interface SearchByRecordsProps {
  isAllRecords?: boolean;
}

export const SearchByRecords: FC<SearchByRecordsProps> = ({ isAllRecords }) => {
  const [searchValue, setSearchValue] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const allRecordsParams = searchParams?.get('allRecords');
  const search = searchParams.get('search');

  useEffect(() => {
    setSearchValue(search || '');
  }, [search]);

  const setQuerySearch = useDebouncedCallback((value: string) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      search: value,
    });
  }, 300);

  const handleChange = (value: string) => {
    setQuerySearch(value);

    setSearchValue(value);
  };

  if (isAllRecords && (!allRecordsParams || allRecordsParams === 'false')) {
    return <div />;
  }

  return (
    <div className="relative flex justify-center">
      <TextInput
        value={searchValue}
        placeholder="Начните поиск"
        searchIcon
        isReset
        className="w-full bg-dark max-w-[540px] xl:max-w-[300px] ml-[-35px]"
        theme="dark"
        onChange={handleChange}
      />
    </div>
  );
};
