import { FC, useState } from 'react';
import { PayloadAction } from '@reduxjs/toolkit';
import { successMessages, warningMessages } from 'constants/messages';
import { useAppDispatch } from 'hooks';
import { IOption } from 'interfaces';
import { IClanPlayer } from 'interfaces/player';
import { ADD_ACCESS_ROLE_OPTIONS } from 'mock';
import { setClanMemberThunk } from 'store/slices/clan/actions';

import { Button, Select } from 'components/ui';
import { notify } from 'utils';

interface AddAccessCardProps {
  accountId: string;
  clanOptions: IOption[];
  onAddAccess: (value: IClanPlayer) => void;
  onClose: () => void;
  isNewPlayer?: boolean;
}

export const AddAccessCard: FC<AddAccessCardProps> = ({
  accountId,
  clanOptions,
  onAddAccess,
  onClose,
  isNewPlayer,
}) => {
  const [selectedRole, setSelectedRole] = useState<IOption>(
    ADD_ACCESS_ROLE_OPTIONS[1]
  );

  const [selectedClan, setSelectedClan] = useState<IOption[]>([]);

  const dispatch = useAppDispatch();

  const handleSelectClans = (value: IOption | IOption[]) => {
    if (Array.isArray(value)) {
      setSelectedClan(value);
    }
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedRole(value);
    }
  };

  const handleClose = () => {
    onClose();

    setSelectedRole({
      value: '',
      label: '',
    });

    setSelectedClan([]);
  };

  const handleSave = () => {
    if (selectedClan.length && selectedRole.value) {
      if (!isNewPlayer) {
        const promises: Promise<PayloadAction<any>>[] = [];

        selectedClan.forEach((clan) => {
          promises.push(
            dispatch(
              setClanMemberThunk({
                clanId: clan.value,
                memberId: accountId,
                roleId: selectedRole.value,
                isAddedFromAccount: true,
              })
            )
          );
        });

        Promise.all(promises).then(() => {
          selectedClan.forEach((clan) => {
            onAddAccess({
              clanId: clan.value,
              clanName: clan.label,
              roleId: selectedRole.value,
              roleName: selectedRole.label,
            });
          });

          notify.success(successMessages.ADD_ACCESS);
        });
      } else {
        selectedClan.forEach((clan) => {
          onAddAccess({
            clanId: clan.value,
            clanName: clan.label,
            roleId: selectedRole.value,
            roleName: selectedRole.label,
          });
        });
      }

      handleClose();
    } else {
      notify.warning(warningMessages.ADD_ACCESS);
    }
  };

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="border border-solid rounded-[10px] border-dark_product">
        <Select
          value={selectedClan}
          options={clanOptions}
          onSelect={handleSelectClans}
          placeholder="Подразделение"
          isMultiple={true}
          isOptionSearch={true}
          className="pl-[16px] rounded-t-[10px] border-b border-solid border-b-dark_product"
        />
        <Select
          value={selectedRole}
          options={ADD_ACCESS_ROLE_OPTIONS}
          onSelect={handleSelectRole}
          placeholder="Роль"
          isOptionEmpty={false}
          className="pl-[16px] rounded-b-[10px] rounded-t-[10px]"
        />
      </div>
      <div className="flex flex-row gap-[12px]">
        <Button
          onClick={handleClose}
          title="Отмена"
          className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
        />
        <Button onClick={handleSave} title="Добавить" className="w-full" />
      </div>
    </div>
  );
};
