import { RefObject, useRef } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cn from 'classnames';
import { ReactComponent as Sort } from 'images/newIcons/arrow.svg';

import { TSetState } from '../../../types';
import DateFilter from '../Filters/DateFilter';
import EntityParameterFilter from '../Filters/EntityParameterFilter';
import { Checkbox } from '../index';

import styles from './STable.module.scss';

export interface STableHeadCell<S> {
  disablePadding: boolean;
  id: string;
  sortableProperty?: S;
  label: string;
  numeric: boolean;
  possibleValues?: string[];
  dateFilter?: boolean;
}

export interface IFilterValue {
  id: string;
  values: string[];
}

interface STableHeaderProps<K> {
  allowSelectRows: boolean;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: K | undefined
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortAsc: boolean;
  sortBy: K | undefined;
  rowCount: number;
  headCells: readonly STableHeadCell<K>[];
  className?: string;
  selectedFilters?: IFilterValue[];
  onFiltersChange?: TSetState<IFilterValue[]>;
  scrollContainerRef: RefObject<HTMLDivElement>;
}

export function STableHeader<S>(props: STableHeaderProps<S>) {
  const {
    onSelectAllClick,
    sortAsc,
    sortBy,
    numSelected,
    rowCount,
    onRequestSort,
    className,
    selectedFilters,
    onFiltersChange,
    scrollContainerRef,
  } = props;

  const tableCellRef = useRef<HTMLTableCellElement>(null);

  const onAddFilter = (field: string, value: string) => {
    onFiltersChange?.((prevState) => {
      const filterIndex = prevState.findIndex((filter) => filter.id === field);

      if (filterIndex > -1) {
        const updatedFilters = [...prevState];

        updatedFilters[filterIndex] = {
          ...updatedFilters[filterIndex],
          values: [...updatedFilters[filterIndex].values, value],
        };

        return updatedFilters;
      }

      return [...prevState, { id: field, values: [value] }];
    });
  };

  const onRemoveFilter = (field: string, value: string) => {
    onFiltersChange?.((prevState) => {
      const filterIndex = prevState.findIndex((filter) => filter.id === field);

      if (filterIndex > -1) {
        const updatedFilters = [...prevState];

        const updatedValues = updatedFilters[filterIndex].values.filter(
          (val) => val !== value
        );

        if (updatedValues.length > 0) {
          updatedFilters[filterIndex] = {
            ...updatedFilters[filterIndex],
            values: updatedValues,
          };
        } else {
          updatedFilters.splice(filterIndex, 1);
        }

        return updatedFilters;
      }

      return prevState;
    });
  };

  const onResetFilter = (field: string) => {
    onFiltersChange?.((prevState) => {
      const filterIndex = prevState.findIndex((filter) => filter.id === field);

      if (filterIndex > -1) {
        const updatedFilters = [...prevState];

        updatedFilters.splice(filterIndex, 1);

        return updatedFilters;
      }

      return prevState;
    });
  };

  return (
    <TableHead
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 9,
      }}
      className={cn(styles['table-root__header'], className)}
    >
      <TableRow>
        <TableCell>
          {props.allowSelectRows && (
            <Checkbox
              className="flex justify-center"
              isChecked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              theme="dark"
              borderColor="#61657C"
            />
          )}
        </TableCell>
        {props.headCells.map((headCell) => (
          <TableCell
            ref={tableCellRef}
            sx={{
              position: 'relative',
            }}
            key={String(headCell.id)}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              sortBy === headCell.sortableProperty ? sortAsc && 'asc' : false
            }
          >
            <div className="relative flex justify-between items-center">
              <div className="tpg-c1 colored-title">{headCell.label}</div>
              <div className="flex gap-1">
                {headCell.sortableProperty && (
                  <Sort
                    className={cn('icon-container cursor-pointer', {
                      '[&>path]:fill-bright_product':
                        sortBy === headCell.sortableProperty && sortAsc,
                    })}
                    onClick={(e) => onRequestSort(e, headCell.sortableProperty)}
                  />
                )}
                {headCell.possibleValues && (
                  <div className="mr-3 mb-4">
                    <EntityParameterFilter
                      field={headCell.id}
                      possibleValues={headCell.possibleValues}
                      onFilterAdd={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      selectedFilters={selectedFilters}
                      resetFilters={onResetFilter}
                      scrollContainerRef={scrollContainerRef}
                      tableCellRef={tableCellRef}
                      containerId="table"
                    />
                  </div>
                )}
                {headCell.dateFilter && (
                  <div className="mr-3 mb-4">
                    <DateFilter
                      field={headCell.id}
                      onFilterAdd={onAddFilter}
                      resetFilters={onResetFilter}
                      selectedFilters={selectedFilters}
                      scrollContainerRef={scrollContainerRef}
                      tableCellRef={tableCellRef}
                      containerId="table"
                    />
                  </div>
                )}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
