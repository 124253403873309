import { useEffect, useMemo, useState } from 'react';
import { HEADER_HEIGHT } from 'constants/common';
import { useAppSelector, useBreakPoint } from 'hooks';
import { IRoomParams } from 'interfaces';
import { roomSelector } from 'store/slices/room/selectors';

import {
  clearLStorageMany,
  getLStorage,
  setLStorage,
} from 'utils/helpers/localStorage';

import { useStreamHeight } from './useStreamHeight';
import { useStreamsDynamicGrid } from './useStreamsDynamicGrid';
import { useWindowSize } from './useWindowSize';

const STORAGE_KEYS = {
  VISIBLE_COUNT: 'visible_count',
  UNLOADED_COUNT: 'unloaded_count',
};

const updateStreamsData = (
  visibleStreams: IRoomParams[],
  newStreams: IRoomParams[],
  maxNumber: number
) => {
  const uniqueNewStreams = newStreams.filter(
    (item) =>
      !visibleStreams.some((item2) => item.livekitName === item2.livekitName)
  );

  const targetLength = Math.ceil(visibleStreams.length / maxNumber) * maxNumber;
  const count = targetLength - visibleStreams.length;

  const streamsToInsert = uniqueNewStreams.slice(0, count);
  const extraStreams = uniqueNewStreams.slice(count);

  visibleStreams.push(...streamsToInsert);

  return { updatedVisibleStreams: visibleStreams, extraStreams };
};

export const useStreamControl = (
  rooms: IRoomParams[],
  isOpenFullScreen: boolean
) => {
  const [visibleStreams, setVisibleStreams] = useState<IRoomParams[]>([]);
  const [unloadedStreams, setUnloadedStreams] = useState<IRoomParams[]>([]);

  const { height, width } = useWindowSize();
  const { rowCount, colCount } = useAppSelector(roomSelector);

  const gridSize = useStreamsDynamicGrid(rooms);

  const breakPoint = useBreakPoint();

  const screenHeight = useMemo(
    () => (isOpenFullScreen ? height : height - HEADER_HEIGHT),
    [isOpenFullScreen, height]
  );

  const streamHeight = useStreamHeight(rooms, screenHeight);

  const streamsInRow =
    colCount === 'auto'
      ? Math.floor(width / (width / gridSize))
      : Number(colCount);

  const streamsInColumn =
    rowCount === 'auto'
      ? Math.floor(screenHeight / streamHeight)
      : Number(rowCount);

  const totalVisibleStreams = streamsInRow * streamsInColumn;

  const unloadedStreamsCount =
    Number(getLStorage(STORAGE_KEYS.UNLOADED_COUNT)) || 0;

  const visibleStreamsCount =
    Number(getLStorage(STORAGE_KEYS.VISIBLE_COUNT)) || 0;

  const maxVisibleStreams = useMemo(
    () =>
      unloadedStreamsCount < unloadedStreams.length
        ? unloadedStreams.length
        : Math.min(unloadedStreamsCount, totalVisibleStreams),
    [unloadedStreamsCount, unloadedStreams, totalVisibleStreams]
  );

  const handleVisibleStreamsChange = () => {
    const count = visibleStreamsCount + maxVisibleStreams;

    const totalVisibleStreams = rooms.slice(0, count);
    const totalUnloadedStreams = rooms.slice(count);

    setLStorage([STORAGE_KEYS.VISIBLE_COUNT, totalVisibleStreams.length]);
    setLStorage([STORAGE_KEYS.UNLOADED_COUNT, totalUnloadedStreams.length]);

    setVisibleStreams(totalVisibleStreams);

    setUnloadedStreams(totalUnloadedStreams);
  };

  useEffect(() => {
    clearLStorageMany(...Object.values(STORAGE_KEYS));

    return () => {
      clearLStorageMany(...Object.values(STORAGE_KEYS));
    };
  }, []);

  useEffect(() => {
    if (!Number.isFinite(totalVisibleStreams)) return;

    const totalVisibleRooms = rooms.slice(0, totalVisibleStreams);
    const totalUnloadedRooms = rooms.slice(totalVisibleStreams);
    const isMobile = breakPoint === 'mobile' || breakPoint === 'tablet';
    const hasMoreRoomsThanVisible = rooms.length > visibleStreamsCount;

    if (
      !visibleStreamsCount ||
      (!isMobile &&
        !unloadedStreamsCount &&
        hasMoreRoomsThanVisible &&
        rooms.length <= totalVisibleStreams)
    ) {
      setLStorage([STORAGE_KEYS.VISIBLE_COUNT, totalVisibleRooms.length]);
      setLStorage([STORAGE_KEYS.UNLOADED_COUNT, totalUnloadedRooms.length]);

      return;
    }

    if (
      visibleStreamsCount &&
      !isMobile &&
      !unloadedStreamsCount &&
      hasMoreRoomsThanVisible &&
      rooms.length > totalVisibleStreams
    ) {
      const { updatedVisibleStreams, extraStreams } = updateStreamsData(
        visibleStreams,
        rooms,
        streamsInRow
      );

      setLStorage([STORAGE_KEYS.VISIBLE_COUNT, updatedVisibleStreams.length]);
      setLStorage([STORAGE_KEYS.UNLOADED_COUNT, extraStreams.length]);
    }
  }, [
    totalVisibleStreams,
    visibleStreams,
    visibleStreamsCount,
    unloadedStreamsCount,
    breakPoint,
    rooms,
  ]);

  useEffect(() => {
    const totalVisibleRooms = rooms.slice(0, visibleStreamsCount);
    const totalUnloadedRooms = rooms.slice(visibleStreamsCount);

    setVisibleStreams(totalVisibleRooms);
    setUnloadedStreams(totalUnloadedRooms);
  }, [visibleStreamsCount, rooms]);

  return {
    visibleStreams,
    unloadedStreams,
    maxVisibleStreams,
    handleVisibleStreamsChange,
  };
};
