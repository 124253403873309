import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ELStorageKeys } from 'constants/localStorage';
import { useEscapeDown } from 'hooks/useEscapeDown';
import { HIGH } from 'hooks/useQualityControl';
import { ReactComponent as FullscreenIcon } from 'images/newIcons/fullSize.svg';
import { ReactComponent as MonitorIcon } from 'images/newIcons/monitor.svg';
import { Track } from 'livekit-client';

import { notify } from 'utils';
import { getLStorage } from 'utils/helpers/localStorage';

interface IControlPanelProps {
  videoEl: HTMLVideoElement | null;
  playerEl: HTMLDivElement | null;
  quality: Track.Dimensions;
  initialVolume?: number;
  isMain?: boolean;
  isShowSwitchButtonMain?: boolean;
  livekitName?: string;
  isFullscreen: boolean;
  onFullscreen: (value: boolean) => void;
  onQualityChange: (dimensions: Track.Dimensions) => void;
  onSelectMainRoom?: (name: string) => void;
}

export const ControlPanel: FC<IControlPanelProps> = ({
  videoEl,
  playerEl,
  quality,
  isMain,
  isShowSwitchButtonMain,
  livekitName,
  isFullscreen,
  onFullscreen,
  onQualityChange,
  onSelectMainRoom,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const hoverTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const fullscreenMode = getLStorage(ELStorageKeys.useCustomFullscreen);

  useEscapeDown(
    () => fullscreenMode === 'on' && isFullscreen && onFullscreen(false)
  );

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        onFullscreen(false);
        onQualityChange(quality);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => () => clearTimeout(hoverTimeout.current), []);

  const toggleFullscreen = (isFullscreen: boolean) => {
    onQualityChange(isFullscreen ? HIGH : quality);
    onFullscreen(isFullscreen);
  };

  const handleFullscreenClick = () => {
    if (fullscreenMode === 'on') {
      toggleFullscreen(!isFullscreen);

      return;
    }

    if (isFullscreen) {
      document.exitFullscreen().catch((err) => notify.error(err.message));
      toggleFullscreen(false);
    } else if (playerEl?.requestFullscreen) {
      playerEl.requestFullscreen().catch((err) => {
        if ('message' in err) notify.error(err.message);
      });

      toggleFullscreen(true);
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout.current);
    setIsHovered(true);
  };

  const handleMouseMove = () => {
    clearTimeout(hoverTimeout.current);
    setIsHovered(true);

    hoverTimeout.current = setTimeout(() => {
      setIsHovered(false);
    }, 2000);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout.current);
    setIsHovered(false);
  };

  return (
    <div
      className={cn(
        'absolute top-0 h-full w-full',
        videoEl && 'hover:transition-all',
        { 'hover:opacity-100': !isHovered }
      )}
      style={{ opacity: !isHovered ? 0 : 'unset' }}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={cn(
          'absolute bottom-0 flex h-[48px] w-full rounded-b-[10px] items-center justify-end bg-gradient-to-t from-neutral-900 px-[24px]',
          {
            '!justify-between': isShowSwitchButtonMain || isMain,
          }
        )}
        style={{ background: 'rgba(10, 12, 23, 0.9)' }}
      >
        {(isShowSwitchButtonMain || isMain) && !isFullscreen ? (
          <div
            className="flex items-center justify-center gap-[4px] cursor-pointer"
            onClick={() =>
              onSelectMainRoom?.((!isMain && livekitName && livekitName) || '')
            }
          >
            <span className="tpg-c2">
              {isMain ? 'Отменить' : 'Сделать главным'}
            </span>
            <div className="text-white flex items-center justify-center [&>svg]:w-[14px] [&>svg]:h-[14px]">
              <MonitorIcon />
            </div>
          </div>
        ) : (
          <div />
        )}
        <div
          className="flex items-center justify-center gap-[4px] cursor-pointer"
          onClick={handleFullscreenClick}
        >
          <span className="tpg-c2">
            {!isFullscreen ? 'На весь экран' : 'Свернуть'}
          </span>
          <div className="text-white flex items-center justify-center [&>svg]:w-[20px] [&>svg]:h-[20px]">
            <FullscreenIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
