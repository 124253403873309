import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useClickOutside } from 'hooks';
import { ReactComponent as DownIcon } from 'images/newIcons/down.svg';
import { IOption } from 'interfaces';

interface StreamsNumberSelectProps {
  label: string;
  count: number;
  onSelect: (count: string) => void;
  selectedOption?: IOption;
}

export const StreamsCountSelect: FC<StreamsNumberSelectProps> = ({
  label,
  count,
  selectedOption: selectedOptionProps,
  onSelect,
}) => {
  const [isOpen, setOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState<IOption>({
    label: 'Авто',
    value: 'auto',
  });

  const [options, setOptions] = useState<IOption[]>([
    {
      label: 'Авто',
      value: 'auto',
    },
  ]);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () => setOpen(false));

  useEffect(() => {
    if (selectedOptionProps) {
      setSelectedOption(selectedOptionProps);
    }
  }, [selectedOptionProps]);

  useEffect(() => {
    if (options.length < count + 1) {
      const countList = Array.from({ length: count }, (_, index) => ({
        label: `${index + 1}`,
        value: `${index + 1}`,
      }));

      setOptions((prev) => [...prev, ...countList]);
    }
  }, [count, options]);

  const handleOptionSelect = (label: string, value: string) => {
    setSelectedOption({ label, value });

    onSelect(value);
  };

  return (
    <div ref={containerRef} className="relative">
      <div
        className="group flex cursor-pointer flex-row justify-between items-center w-full"
        onClick={() => setOpen(!isOpen)}
      >
        <span
          className={cn(
            'tpg-c1 text-tpg_base group-hover:text-bright_product transition',
            {
              'text-tpg_title': isOpen,
            }
          )}
        >
          {label}
        </span>
        <DownIcon
          className={cn(
            'w-[18px] h-[18px] -rotate-90 [&>path]:fill-tpg_base group-hover:[&>path]:!fill-bright_product transition',
            {
              '[&>path]:!fill-tpg_title': isOpen,
            }
          )}
        />
      </div>
      {isOpen && (
        <div className="z-[10] w-[195px] flex flex-col gap-[4px] right-[-211px] top-[-12px] py-[12px] px-[16px] justify-start absolute bg-ultrablack border border-solid rounded-[10px] rounded-tl-none border-tpg_light">
          {options.map(({ label, value }) => (
            <span
              key={label}
              className={cn(
                'cursor-pointer tpg-c1 text-tpg_base hover:text-bright_product transition',
                {
                  '!text-bright_product': selectedOption.label === label,
                }
              )}
              onClick={() => handleOptionSelect(label, value)}
            >
              {label}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
