import { FC } from 'react';

import { Button } from 'components/ui';

interface MoreButtonProps {
  info: string;
  title: string;
  onClick: () => void;
}

export const MoreButton: FC<MoreButtonProps> = ({ info, title, onClick }) => (
  <div className="flex flex-col items-center gap-[16px] py-[32px] text-center bg-ultrablack sticky z-[9]">
    <span className="tpg-b2 text-tpg_base">{info}</span>
    <Button
      title={title}
      onClick={onClick}
      className="w-full max-w-[360px] h-[40px]"
    />
  </div>
);
