import { FC, useEffect, useRef, useState } from 'react';
import Map, {
  Layer,
  MapRef,
  Marker,
  Source,
  ViewStateChangeEvent,
} from 'react-map-gl';

import {
  getAllLiveKitTokens,
  getAllRooms,
  updateRoomLocation,
} from '../../../../api/room';
import { IGetRoomResponse } from '../../../../api/types/room';
import { Room } from '../../../../components/Room';
import { Spinner } from '../../../../components/ui';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import useEvent from '../../../../hooks/useEvent';
import { useQualityControl } from '../../../../hooks/useQualityControl';
import { authSelector, clansListSelector } from '../../../../store';
import { getClansThunk } from '../../../../store/slices/clan/actions';
import { convertRoomNameToParams } from '../../../../utils';
import { Event, EventTypes } from '../../../../utils/events/events';

import './style.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;

const initialLatitude = 60;
const initialLongitude = 15;
const initialZoom = 3;

export const NavigatorMapComponent: FC = () => {
  const [isMapLoaded, setMapLoaded] = useState(false);
  const [roomLoading, setRoomLoading] = useState(true);
  const [roomToken, setRoomToken] = useState('');
  const [roomClanName, setRoomClanName] = useState('');
  const [latitude, setLatitude] = useState(initialLatitude);
  const [longitude, setLongitude] = useState(initialLongitude);
  const [zoom, setZoom] = useState(initialZoom);

  const [room, setRoom] = useState<IGetRoomResponse & { clan_id: string }>();

  const [streamLocation, setStreamLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  const mapRef = useRef<MapRef>(null);
  const quality = useQualityControl(1);
  const { id, mfaEnabled, mfaRequired } = useAppSelector(authSelector);
  const clans = useAppSelector(clansListSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setNavigatorRoomLocation();
    dispatch(getClansThunk());
  }, []);

  useEffect(() => {
    if (room && clans.length) {
      const clan = clans.find((clan) => clan.id === room?.clan_id);

      if (clan) {
        setRoomClanName(clan.name);
      }
    }
  }, [room, clans]);

  const NavigatorMapLocationUpdatedEvent: Event | undefined = useEvent(
    EventTypes.NAVIGATOR_MAP_LOCATION_UPDATED
  );

  useEffect(() => {
    if (NavigatorMapLocationUpdatedEvent !== undefined && mapRef.current) {
      const { lng, lat } = mapRef.current.getCenter();
      setStreamLocation({ longitude: lng, latitude: lat });

      if (room) {
        updateRoomLocation({
          clanId: room.clan_id,
          roomName: room.name,
          lat,
          lon: lng,
          zoom: Math.round(zoom),
        });
      }
    }
  }, [NavigatorMapLocationUpdatedEvent]);

  const setNavigatorRoomLocation = async () => {
    if (!id) {
      return;
    }

    const rooms = await getAllRooms();

    // Temporarily working with one room

    if (rooms.data?.length) {
      const room = rooms.data[0];

      const token = await getAllLiveKitTokens([
        convertRoomNameToParams(room.livekit_name),
      ]);

      if (token.data) {
        setRoomToken(token.data.items[0].token);
      }
      setRoom(room);

      if (room.location?.lat && room.location?.lon) {
        setStreamLocation({
          longitude: room.location.lon,
          latitude: room.location.lat,
        });

        setZoom(room.zoom);
        setLatitude(room.location.lat);
        setLongitude(room.location.lon);
      }
    }
    setRoomLoading(false);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const handleMapDrag = (e: ViewStateChangeEvent) => {
    setLatitude(e.viewState.latitude);
    setLongitude(e.viewState.longitude);
    setZoom(e.viewState.zoom);
  };

  if (!(mfaRequired && mfaEnabled)) {
    return (
      <div className="h-full flex justify-center items-center">
        <h4 className="tpg-c2 mt-3.5">
          Работа с картой доступна только аккаунтам с двухфакторной авторизацией
        </h4>
      </div>
    );
  }

  return (
    <div className="relative w-full h-screen">
      {roomLoading && (
        <div className="h-full flex justify-center items-center">
          <Spinner />
        </div>
      )}
      {!roomLoading && !room && (
        <div className="h-full flex justify-center items-center">
          <h4 className="tpg-c2 mt-3.5">
            Ни один источник сейчас не ведет эфир
          </h4>
        </div>
      )}
      {!roomLoading && room && (
        <>
          <Map
            ref={mapRef}
            mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
            minZoom={1.5}
            onLoad={handleMapLoad}
            latitude={latitude}
            longitude={longitude}
            onDrag={handleMapDrag}
            zoom={zoom}
            onZoom={handleMapDrag}
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5 h-5 rounded-full pointer-events-none">
              <div className="absolute top-1/2 left-0 w-full h-0.5  border-solid border-1 border-black bg-white transform -translate-y-1/2" />
              <div className="absolute top-0 left-1/2 w-0.5 h-full  border-solid border-1 border-black bg-white transform -translate-x-1/2" />
            </div>
            {streamLocation && (
              <Marker
                longitude={streamLocation?.longitude}
                latitude={streamLocation?.latitude}
              >
                <div className="w-10 h-10 bg-[orangered] border-2 border-white border-solid rounded-full flex items-center justify-center text-white font-bold uppercase">
                  ОУ
                </div>
              </Marker>
            )}
            ))
            <Source
              id="google"
              key="google"
              type="raster"
              tiles={[
                'https://mt0.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}',
              ]}
            >
              <Layer id="google" type="raster" source="google" />
            </Source>
          </Map>
          <div className="absolute top-0 left-0 z-[1] w-[470px]">
            {id && (
              <Room
                accountId={id}
                livekitName={room.livekit_name}
                roomName={room.name}
                publisherName={room.publisher_name}
                clanId={room.clan_id}
                clanName={roomClanName}
                token={roomToken}
                height={264}
                isMain={false}
                isShowSwitchButtonMain={false}
                quality={quality}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
