import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Map, { Layer, MapboxEvent, MapRef, Marker, Source } from 'react-map-gl';

import { Room } from '../../../../components/Room';
import { Spinner } from '../../../../components/ui';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useQualityControl } from '../../../../hooks/useQualityControl';
import { IRoom } from '../../../../interfaces';
import { authSelector, clansSelector } from '../../../../store';
import { getClansThunk } from '../../../../store/slices/clan/actions';
import { roomActions, roomSelector } from '../../../../store/slices/room';
import {
  getAllLiveKitTokensThunk,
  getAllRoomsThunk,
} from '../../../../store/slices/room/actions';
import { rolesValuesMap } from '../../../../types/player';
import { convertRoomNameToParams } from '../../../../utils';

import './style.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;

const LP_INTERVAL_MS = 5000;

export const ConsumerMapComponent: FC = () => {
  const [isMapLoaded, setMapLoaded] = useState(false);

  const [selectedRoom, setSelectedRoom] = useState<
    (IRoom & { clanId: string; clanName: string }) | null
  >(null);

  const [roomPopupVisible, setRoomPopupVisible] = useState(false);

  const [roomPopupVerticalDirection, setRoomPopupVerticalDirection] = useState<
    'top' | 'bottom'
  >('top');

  const [roomPopupHorizontalDirection, setRoomPopupHorizontalDirection] =
    useState<'left' | 'right' | 'center'>('center');

  const mapRef = useRef<MapRef>(null);
  const quality = useQualityControl(1);

  const {
    id: accountId,
    roles,
    mfaRequired,
    mfaEnabled,
  } = useAppSelector(authSelector);

  const { treeRooms, rooms: roomsData } = useAppSelector(roomSelector);
  const dispatch = useAppDispatch();
  const { clans } = useAppSelector(clansSelector);

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const rooms = useMemo(
    () =>
      treeRooms.flatMap(
        (data) =>
          data.rooms?.map((room) => ({
            ...room,
            clanName: data.clanName,
            clanId: data.clanId,
          })) || []
      ),
    [treeRooms]
  );

  const filteredRooms = useMemo(
    () =>
      rooms
        .filter(
          (room) =>
            room.accountId === accountId ||
            roles.some(
              (role) =>
                role.clan_id === room.clanId &&
                role.role_id !== rolesValuesMap.user
            )
        )
        .sort((a, b) =>
          a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0
        ),
    [rooms, accountId, roles]
  );

  useEffect(() => {
    const getRooms = () => {
      dispatch(getAllRoomsThunk());
    };

    getRooms();
    dispatch(getClansThunk());

    const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

    return () => {
      clearInterval(roomsInterval);
    };
  }, []);

  const roomsWithoutToken = useMemo(
    () =>
      filteredRooms
        .filter((room) => !room.token)
        .map((room) => room.livekitName),
    [filteredRooms]
  );

  useEffect(() => {
    dispatch(roomActions.setTreeRooms({ clans }));
  }, [roomsData]);

  useEffect(() => {
    dispatch(
      getAllLiveKitTokensThunk(
        roomsWithoutToken.map((name) => convertRoomNameToParams(name))
      )
    );
  }, [roomsWithoutToken]);

  const handleMarkerClick = (
    e: MapboxEvent<MouseEvent>,
    room: IRoom & { clanId: string; clanName: string }
  ) => {
    const originalEvent = e.originalEvent;
    originalEvent.stopPropagation();

    const block = originalEvent.target as HTMLDivElement;
    const blockRect = block.getBoundingClientRect();
    const popupHeight = 264;
    const popupWidth = 470;

    const spaceOnLeft = blockRect.left;
    const spaceOnRight = window.innerWidth - blockRect.right;

    if (blockRect.top >= popupHeight) {
      setRoomPopupVerticalDirection('top');
    } else {
      setRoomPopupVerticalDirection('bottom');
    }

    if (spaceOnRight >= popupWidth / 2 && spaceOnLeft >= popupWidth / 2) {
      setRoomPopupHorizontalDirection('center');
    } else if (spaceOnRight >= popupWidth) {
      setRoomPopupHorizontalDirection('right');
    } else if (spaceOnLeft >= popupWidth) {
      setRoomPopupHorizontalDirection('left');
    }

    setSelectedRoom(room);
    setRoomPopupVisible(true);
  };

  if (!(mfaRequired && mfaEnabled)) {
    return (
      <div className="h-full flex justify-center items-center">
        <h4 className="tpg-c2 mt-3.5">
          Данные от штурмана доступны только аккаунтам с двухфакторной
          авторизацией
        </h4>
      </div>
    );
  }

  return (
    <div className="relative w-full h-screen">
      {!isMapLoaded && (
        <div className="h-full flex justify-center items-center">
          <Spinner />
        </div>
      )}
      {
        <Map
          ref={mapRef}
          mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
          minZoom={1.5}
          onLoad={handleMapLoad}
          initialViewState={{
            longitude: 60,
            latitude: 15,
            zoom: 1.5,
          }}
          onClick={() => setRoomPopupVisible(false)}
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5 h-5 border-2 border-black rounded-full pointer-events-none">
            <div className="absolute top-1/2 left-0 w-full h-0.5 bg-white border-t border-b border-black transform -translate-y-1/2" />
            <div className="absolute top-0 left-1/2 w-0.5 h-full bg-white border-l border-r border-black transform -translate-x-1/2" />
          </div>
          {filteredRooms.map((room) => (
            <Marker
              key={room.livekitName}
              longitude={room.location.lon}
              latitude={room.location.lat}
              onClick={(e) => {
                handleMarkerClick(e, room);
              }}
            >
              <div className="w-10 h-10 bg-[orangered] border-2 border-white border-solid rounded-full flex items-center justify-center text-white font-bold uppercase">
                ОУ
              </div>
              {roomPopupVisible && selectedRoom && (
                <div
                  style={{
                    position: 'absolute',
                    top:
                      roomPopupVerticalDirection === 'bottom' ? '50px' : 'auto',
                    bottom:
                      roomPopupVerticalDirection === 'top' ? '50px' : 'auto',
                    left:
                      roomPopupHorizontalDirection === 'center'
                        ? '-200px'
                        : roomPopupHorizontalDirection === 'left'
                        ? 'auto'
                        : '40px',
                    right:
                      roomPopupHorizontalDirection === 'center'
                        ? 'auto'
                        : roomPopupHorizontalDirection === 'left'
                        ? '40px'
                        : 'auto',
                    zIndex: 10,
                    width: '470px',
                  }}
                >
                  <Room
                    accountId={accountId || ''}
                    livekitName={selectedRoom.livekitName}
                    roomName={selectedRoom.roomName}
                    publisherName={selectedRoom.publisherName}
                    clanId={selectedRoom.clanId}
                    clanName={selectedRoom.clanName}
                    token={selectedRoom.token}
                    height={264}
                    isMain={false}
                    isShowSwitchButtonMain={false}
                    quality={quality}
                  />
                </div>
              )}
            </Marker>
          ))}
          <Source
            id="google"
            key="google"
            type="raster"
            tiles={['https://mt0.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}']}
          >
            <Layer id="google" type="raster" source="google" />
          </Source>
        </Map>
      }
    </div>
  );
};
