import { ITag } from 'interfaces';

export interface ICard {
  id: string;
  src: string;
  time: string;
  tags?: ITag[];
  owner?: string;
}

export enum ESearchParamsStream {
  ShowAllClans = 'showAllClans',
  HideAllClans = 'hideAllClans',
  ShowClans = 'showClans',
  Pinned = 'pinned',
}
