import { notify } from 'utils';

export const copyToClipboard = (
  text: string,
  message = 'Скопировано в буфер обмена'
) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message && notify.success(message);
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    // for mobile devices and older browsers
    const textarea = document.createElement('textarea');

    textarea.value = text;
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    message && notify.success(message);
  }
};
