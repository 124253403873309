import { Key, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import cn from 'classnames';
import { useDebounce } from 'usehooks-ts';

import {
  NOTHING_WAS_FOUND_MESSAGE,
  STREAMS_COLORS,
} from '../../../constants/routes';
import useEvent from '../../../hooks/useEvent';
import { TSetState } from '../../../types';
import eventDispatcher from '../../../utils/events/eventDispatcher';
import { Event, EventTypes } from '../../../utils/events/events';
import { Checkbox } from '../index';
import { Loader } from '../Loader';

import { STableCellExtraProps } from './STableCell';
import { IFilterValue, STableHeadCell, STableHeader } from './STableHeader';

import './style.scss';
import styles from './STable.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: STREAMS_COLORS.DARK_PRODUCT,
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#E0C2FF',
      light: '#F5EBFF',
      // dark: will bes calculated from palette.secondary.main,
      contrastText: '#47008F',
    },
  },
});

export interface STableFetchParams {
  size: number;
  start: number;
  searchQuery?: string;
  filter?: IFilterValue[];
}

interface RowsWithTotalCount<R> {
  rows: R[];
  total: number;
}

interface STableProps<R, S> {
  isEditMode?: boolean;
  fetchRows: (params: STableFetchParams) => Promise<RowsWithTotalCount<R>>;
  searchQuery?: string;
  handleSelectedRows?: (rows: R[]) => void;
  handleRowClick?: (row: R, id: Key) => void;
  handleRowUpdate?: (sourceRow: R) => R;
  rowIdExtractor: (row: R) => Key;
  renderRowCells: (row: R, props: STableCellExtraProps<R>) => React.ReactNode;
  headCells?: STableHeadCell<S>[];
  classNames?: {
    height?: string;
    root?: string;
    table?: string;
    row?: string;
    cell?: string;
    body?: string;
    header?: string;
  };
  isPagination?: boolean;
  role?: string;
  isCheckbox?: boolean;
  selectedFilters?: IFilterValue[];
  onFiltersChange?: TSetState<IFilterValue[]>;
}

const DEFAULT_ROWS_PER_PAGE = 25;

export const getTablePagingInitialParamsBasedOnUrl = (
  searchParams: URLSearchParams,
  searchQueryFromProps?: string
) => {
  const urlSizeParam = searchParams.get('size');
  const urlPageParam = searchParams.get('page');
  const urlQueryParam = searchParams.get('query') || searchQueryFromProps;

  return {
    rowsPerPage: urlSizeParam ? +urlSizeParam : DEFAULT_ROWS_PER_PAGE,
    page: urlPageParam ? +urlPageParam : 0,
    query: urlQueryParam || '',
  };
};

export default function STable<R, S>({
  isCheckbox = true,
  ...props
}: STableProps<R, S>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTableParamsFromUrl = getTablePagingInitialParamsBasedOnUrl(
    searchParams,
    props.searchQuery
  );

  const [sortAsc, setSortAsc] = useState(false);
  const [pending, setPending] = useState(false);

  const [sortBy, setSortBy] = useState<S | undefined>(undefined);
  const [selectedKeys, setSelectedKeys] = useState<readonly Key[]>([]);
  const [page, setPage] = useState(initialTableParamsFromUrl.page);

  const [rowsPerPage, setRowsPerPage] = useState(
    initialTableParamsFromUrl.rowsPerPage
  );

  const [rows, setRows] = useState<R[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const debouncedSearchQuery = useDebounce(props.searchQuery, 200);

  const resetUserTableSelectionEvent: Event | undefined = useEvent(
    EventTypes.RESET_TABLE_SELECTION
  );

  useEffect(() => {
    if (resetUserTableSelectionEvent !== undefined) {
      setSelectedKeys([]);
    }
  }, [resetUserTableSelectionEvent]);

  useEffect(() => {
    eventDispatcher.dispatch({
      name: EventTypes.TABLE_SELECTION_CHANGED,
      data: {
        selectedAmount: selectedKeys.length,
      },
    });
  }, [selectedKeys]);

  useEffect(() => {
    setSearchParams((prev) => {
      rowsPerPage != DEFAULT_ROWS_PER_PAGE &&
        prev.set('size', `${rowsPerPage.toString()}`);

      page != 0 && prev.set('page', `${page.toString()}`);
      debouncedSearchQuery && prev.set('query', `${debouncedSearchQuery}`);

      return prev;
    });
  }, [debouncedSearchQuery, page, rowsPerPage, sortBy]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);

    setSearchParams((prev) => {
      prev.set('page', '0');
      prev.set('size', `${DEFAULT_ROWS_PER_PAGE}`);

      return prev;
    });
  }, [props.selectedFilters]);

  useEffect(() => {
    if (sortAsc) {
      setPending(true);

      props
        .fetchRows({
          size: rowsPerPage,
          start: page * rowsPerPage,
          searchQuery: debouncedSearchQuery,
          filter: props.selectedFilters,
        })
        .then((r) => {
          const sortedRows = [...r.rows].sort((a, b) => {
            const rowA = a?.[sortBy as keyof R];
            const rowB = b?.[sortBy as keyof R];

            if (typeof rowA === 'string' && typeof rowB === 'string') {
              if (rowA.toLowerCase() < rowB.toLowerCase()) return -1;

              if (rowA.toLowerCase() > rowB.toLowerCase()) return 1;

              return 0;
            }

            if (typeof rowA === 'number' && typeof rowB === 'number')
              return rowA - rowB;

            console.log(
              'Несовместимые или неподдерживаемые типы данных для сортировки'
            );

            return 0;
          });

          setRows(sortedRows);
          setTotalCount(r.total);
        })
        .finally(() => setPending(false));
    } else {
      setPending(true);

      props
        .fetchRows({
          size: rowsPerPage,
          start: page * rowsPerPage,
          searchQuery: debouncedSearchQuery,
          filter: props.selectedFilters,
        })
        .then((r) => {
          setRows(r.rows);
          setTotalCount(r.total);
        })
        .finally(() => setPending(false));
    }
  }, [
    sortBy,
    sortAsc,
    props.fetchRows,
    props.selectedFilters,
    rowsPerPage,
    page,
    debouncedSearchQuery,
  ]);

  useEffect(() => {
    const page = Number(searchParams.get('page'));
    const size = Number(searchParams.get('size'));
    setPage(page);
    setRowsPerPage(size);
  }, [searchParams]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: S | undefined
  ) => {
    const isAsc = sortBy === property && sortAsc;

    setSortAsc(!isAsc);
    setSortBy(property);
  };

  // TODO handle selectAll properly including remote items? see @useTableCheck once implement check logic
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map(props.rowIdExtractor);

      setSelectedKeys(newSelected);

      props.handleSelectedRows?.(
        rows.filter((row) => newSelected.includes(props.rowIdExtractor(row)))
      );

      return;
    }

    setSelectedKeys([]);
  };

  const onRowSelected = (key: Key) => {
    const selectedIndex = selectedKeys.indexOf(key);
    let newSelected: readonly Key[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedKeys, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedKeys.slice(1));
    } else if (selectedIndex === selectedKeys.length - 1) {
      newSelected = newSelected.concat(selectedKeys.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedKeys.slice(0, selectedIndex),
        selectedKeys.slice(selectedIndex + 1)
      );
    }

    props.handleSelectedRows?.(
      rows.filter((row) => newSelected.includes(props.rowIdExtractor(row)))
    );

    setSelectedKeys(newSelected);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: Key) => selectedKeys.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const renderTableRow = (row: R, index: number) => {
    const rowId = props.rowIdExtractor(row);
    const isItemSelected = isSelected(rowId);

    const handleRowUpdate = (updatedRow: R) => {
      const updated =
        (props.handleRowUpdate && props.handleRowUpdate(updatedRow)) ||
        updatedRow;

      const currentRowIndex = rows.indexOf(row);

      if (currentRowIndex >= 0) {
        const updatedRows = [...rows];

        updatedRows[currentRowIndex] = updated;
        setRows(updatedRows);
      }
    };

    const handleRowNumberClick = (
      event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
    ) => {
      event.stopPropagation();
      onRowSelected(rowId);
    };

    return (
      <TableRow
        hover
        onClick={() => props.handleRowClick?.(row, rowId)}
        role={props.role ?? 'checkbox'}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rowId}
        selected={isItemSelected}
        sx={{ cursor: props.handleRowClick ? 'pointer' : 'default' }}
        className={cn('group hover:!bg-ultrablack', props.classNames?.row)}
      >
        {isCheckbox && (
          <TableCell
            padding="checkbox"
            onClick={(e) => handleRowNumberClick(e)}
            className={cn(
              'first-column group-hover:!bg-[inherit] !border-b-tpg_light !bg-light',
              props.classNames?.cell
            )}
          >
            {props.handleSelectedRows ? (
              <Checkbox
                isChecked={selectedKeys.includes(rowId)}
                onChange={() => {}}
                className="flex justify-center"
                theme="dark"
                borderColor="#61657C"
              />
            ) : (
              <div className="flex justify-center text-primary">
                {index + 1}
              </div>
            )}
          </TableCell>
        )}
        {props.renderRowCells(row, {
          handleRowUpdate,
          key: index,
          isEditMode: props.isEditMode || false,
          row,
          className: cn(
            'group-hover:!bg-[inherit] !border-b-tpg_light !bg-light',
            props.classNames?.cell
          ),
        })}
      </TableRow>
    );
  };

  if (pending)
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <ThemeProvider theme={theme}>
      <div
        id="table"
        className={cn(
          'relative overflow-hidden h-[calc(100vh-137px)]',
          props.classNames?.height
        )}
      >
        <div className="border border-solid rounded-[10px] border-tpg_light">
          <div
            ref={scrollContainerRef}
            className={cn(
              styles['table-root'],
              'rounded-[10px] group !max-h-[calc(100vh-192px)]',
              {
                '!rounded-b-none':
                  (searchParams && !rows.length) ||
                  (props.isPagination && !!rows.length),
              },
              props.classNames?.root
            )}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              style={{ overflowX: 'initial' }}
              className={props.classNames?.table}
              size={'medium'}
            >
              {props.headCells && (
                <STableHeader
                  headCells={props.headCells}
                  allowSelectRows={props.handleSelectedRows !== undefined}
                  numSelected={selectedKeys.length}
                  sortAsc={sortAsc}
                  sortBy={sortBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  className={props.classNames?.header}
                  selectedFilters={props.selectedFilters}
                  onFiltersChange={props.onFiltersChange}
                  scrollContainerRef={scrollContainerRef}
                />
              )}
              <TableBody
                className={cn('[&>tr>td]:h-[93px]', props.classNames?.body)}
                sx={{
                  '& tr:last-child td': { borderBottom: 'none !important' },
                }}
              >
                {rows.map((r, idx) =>
                  renderTableRow(r, idx + page * rowsPerPage)
                )}
              </TableBody>
            </Table>
          </div>
          {searchParams && !rows.length && (
            <div className="flex items-center justify-center w-full py-[10px] bg-light rounded-b-[10px]">
              <span className="text-tpg_base tpg-c2 !text-lg">
                {NOTHING_WAS_FOUND_MESSAGE}
              </span>
            </div>
          )}
          {props.isPagination && !!rows.length && (
            <TablePagination
              labelRowsPerPage="Строк на странице"
              labelDisplayedRows={(args) =>
                `${args.from}-${args.to} из ${args.count}`
              }
              rowsPerPageOptions={[100, 50, 25, 10]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              sx={{
                position: 'sticky',
                bottom: 0,
                zIndex: 2,
                backgroundColor: '#1D1F2D',
                borderTop: '1px solid #4D5064',
                borderBottomRightRadius: '10px',
                borderBottomLeftRadius: '10px',
              }}
              onPageChange={(e, page) => setPage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
}
