import { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ELStorageKeys } from 'constants/localStorage';
import { warningMessages } from 'constants/messages';
import { useAppSelector, useBreakPoint, useRoomListeners } from 'hooks';
import { HIGH } from 'hooks/useQualityControl';
import { useStreamParams } from 'hooks/useStreamParams';
import { useToastManager } from 'hooks/useToastManager';
import {
  DisconnectReason,
  RemoteTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
  RoomEvent,
  Track,
} from 'livekit-client';
import { roomSelector } from 'store/slices/room/selectors';

import { TooltipWrap } from 'components/ui';
import { getLStorage } from 'utils/helpers/localStorage';

import { ControlPanel } from '../ControlPanel';
import { defaultVolume } from '../ControlPanel/constants';

import './style.scss';

interface IViewerPlayerProps {
  quality: Track.Dimensions;
  livekitName?: string;
  isMuted?: boolean;
  publisherName?: string;
  clanName?: string;
  className?: string;
  isMain?: boolean;
  isMainBlock?: boolean;
  isShowSwitchButtonMain?: boolean;
  onSelectMainRoom?: (name: string) => void;
}

export const ViewerPlayer: FC<IViewerPlayerProps> = ({
  livekitName,
  isMuted,
  publisherName,
  clanName,
  className,
  isMain,
  isMainBlock = false,
  quality,
  isShowSwitchButtonMain,
  onSelectMainRoom,
}) => {
  const [params, setParams] = useState({
    bitrate: 0,
    packetsLost: 0,
    framesPerSecond: 0,
    frameWidth: 0,
    frameHeight: 0,
  });

  const [isDisconnected, setDisconnected] = useState(false);
  const [isFullscreen, setFullscreen] = useState(false);

  const { metaInfo } = useAppSelector(roomSelector);

  const { getBitrate, getFramesPerSecond } = useStreamParams();

  const videoEl = useRef<HTMLVideoElement>(null);
  const playerEl = useRef<HTMLDivElement>(null);
  const videoPublication = useRef<RemoteTrackPublication | null>(null);

  const breakPoint = useBreakPoint();

  const { showToast } = useToastManager(3);

  const isMobile = breakPoint === 'mobile';

  const fullscreenMode = getLStorage(ELStorageKeys.useCustomFullscreen);

  useEffect(() => {
    if (quality.height !== videoPublication.current?.dimensions?.height) {
      videoPublication.current?.setVideoDimensions(quality);
    }
  }, [quality, videoPublication.current]);

  const attachTracks = useCallback(
    (track: RemoteTrack, publication: RemoteTrackPublication) => {
      if (videoEl.current) {
        const ref = videoEl.current;

        track.attach(ref);

        if (isMuted) {
          ref.muted = true;
          ref.volume = 0;
        }
      }

      if (track.kind === Track.Kind.Video) {
        isMain
          ? publication.setVideoDimensions(HIGH)
          : publication.setVideoDimensions(quality);

        videoPublication.current = publication;
      }

      const videoTrack = track as RemoteVideoTrack;

      const updateStats = async () => {
        const stats = await videoTrack.receiver?.getStats();

        stats?.forEach((stat) => {
          if (stat.kind === 'video') {
            const currentBitrate = getBitrate(stat.bytesReceived);

            const currentFramesPerSecond = getFramesPerSecond(
              stat?.framesPerSecond,
              stat.framesReceived
            );

            if (currentBitrate) {
              setParams((prevParams) => ({
                ...prevParams,
                bitrate: currentBitrate,
              }));
            }

            if (stat.packetsLost) {
              setParams((prevParams) => ({
                ...prevParams,
                packetsLost: stat.packetsLost,
              }));
            }

            if (currentFramesPerSecond) {
              setParams((prevParams) => ({
                ...prevParams,
                framesPerSecond: currentFramesPerSecond,
              }));
            }

            if (stat.frameWidth && stat.frameHeight) {
              setParams((prevParams) => ({
                ...prevParams,
                frameWidth: !isNaN(stat.frameWidth)
                  ? Math.round(stat.frameWidth)
                  : 0,
                frameHeight: !isNaN(stat.frameHeight)
                  ? Math.round(stat.frameHeight)
                  : 0,
              }));
            }
          }
        });
      };

      const interval = setInterval(updateStats, 1000);

      return () => {
        clearInterval(interval);
      };
    },
    [videoEl, quality]
  );

  useRoomListeners({
    [RoomEvent.TrackSubscribed]: attachTracks,
    [RoomEvent.TrackMuted]: (pub) => {
      if (pub.kind === Track.Kind.Video && pub.isMuted) {
        if (publisherName) {
          showToast('warning', `${publisherName} поставил эфир на паузу`);

          return;
        }

        showToast('warning', warningMessages.PAUSE_STREAM);
      }
    },
    [RoomEvent.TrackUnmuted]: (pub) => {
      if (pub.kind === Track.Kind.Video && !pub.isMuted) {
        if (publisherName) {
          showToast('warning', `${publisherName} возобновил эфир`);

          return;
        }

        showToast('warning', warningMessages.RESUME_STREAM);
      }
    },
    [RoomEvent.Connected]: () => {
      setDisconnected(false);

      console.log('Connected to the room:>>', livekitName);
    },
    [RoomEvent.ConnectionStateChanged]: (evt) => {
      console.log(`Connection state changed for room:>> ${evt}`, livekitName);
    },
    [RoomEvent.SignalConnected]: () => {
      console.log('Signal connected for room:>>', livekitName);
    },
    [RoomEvent.Disconnected]: (pub) => {
      console.log('Disconnected from the room:>>', livekitName);

      if (typeof pub === 'number') {
        console.log('Disconnect reason :>>', DisconnectReason[pub]);

        if (pub === DisconnectReason.ROOM_DELETED) {
          setDisconnected(true);

          if (publisherName) {
            showToast('warning', `${publisherName} завершил эфир`);

            return;
          }
        }
      }
    },
  });

  const handleQualityChange = (dimensions: Track.Dimensions) => {
    videoPublication.current?.setVideoDimensions(dimensions);
  };

  const handleFullscreenClick = (value: boolean) => {
    setFullscreen(value);
  };

  return (
    <div
      id={livekitName}
      ref={playerEl}
      className={cn(
        'flex overflow-hidden justify-center relative w-full h-full',
        className,
        {
          '!fixed !w-[100vw] !h-[100vh] top-0 left-0 z-[12] !bg-ultrablack':
            isFullscreen && fullscreenMode === 'on',
        }
      )}
    >
      <video className="w-full" ref={videoEl} />
      {metaInfo === 'show' &&
        !isMobile &&
        (!isMainBlock || (isMainBlock && isMain)) && (
          <div className="absolute top-[14px] right-[12px] z-[2] h-[30px] flex gap-[4px] text tpg-c2">
            {!!(params.frameWidth && params.frameHeight) && (
              <span>{`${params.frameWidth} x ${params.frameHeight} `}</span>
            )}
            <span>{`${params.bitrate} kbps `}</span>
            <span>{`${params.framesPerSecond} fps `}</span>
            <span>{`${params.packetsLost} lost`}</span>
          </div>
        )}
      {publisherName && clanName && (
        <div className="absolute top-[12px] left-[12px] flex flex-row gap-[5px] items-center h-[24px] z-[1] px-[5px] py-[1px] bg-ultrablack rounded-[5px] cursor-default">
          <TooltipWrap
            title={publisherName}
            placement="top"
            tooltipClassName="!bg-ultrablack"
            isTooltipShown={publisherName.length > 15}
          >
            <span className="tpg-c2 block overflow-hidden whitespace-nowrap text-ellipsis max-w-[150px]">
              {publisherName}
            </span>
          </TooltipWrap>
          |
          <TooltipWrap
            title={clanName}
            placement="top"
            tooltipClassName="!bg-ultrablack"
            isTooltipShown={clanName.length > 10}
          >
            <span className="tpg-c2 block overflow-hidden whitespace-nowrap text-ellipsis  max-w-[101px]">
              {clanName}
            </span>
          </TooltipWrap>
        </div>
      )}
      {isDisconnected && (
        <span className="absolute tpg-c2 left-1/2 -translate-x-1/2 top-2/4 z-[10]">
          Эфир завершён
        </span>
      )}
      {(!isMainBlock || (isMainBlock && isMain)) && (
        <ControlPanel
          playerEl={playerEl.current}
          videoEl={videoEl.current}
          quality={quality}
          initialVolume={isMuted ? 0 : defaultVolume}
          isMain={isMain}
          isFullscreen={isFullscreen}
          isShowSwitchButtonMain={isShowSwitchButtonMain}
          livekitName={livekitName}
          onFullscreen={handleFullscreenClick}
          onQualityChange={handleQualityChange}
          onSelectMainRoom={onSelectMainRoom}
        />
      )}
    </div>
  );
};
