import { createSlice } from '@reduxjs/toolkit';
import { groupRoomsAdapter } from 'api/helpers/roomAdapter';
import { IDeleteRoomInput } from 'api/types/room';
import { AxiosError } from 'axios';
import { SEPARATOR } from 'constants/livekit';
import { ELStorageKeys } from 'constants/localStorage';
import { reducersNames } from 'constants/reducers';
import {
  IGroupRooms,
  IRoomsSlice,
  ITreeRooms,
  TSliceWithPromiseFlags,
} from 'interfaces';
import { IClan } from 'interfaces/clan';
import { ESearchParamsStream } from 'interfaces/stream';

import { updateTreeRoom } from 'utils';
import { getLStorage } from 'utils/helpers/localStorage';

import {
  createRoomIngressThunk,
  createRoomThunk,
  deleteRoomThunk,
  getAllLiveKitTokensThunk,
  getAllRoomsThunk,
  getClanRoomsThunk,
  getLiveKitTokenThunk,
} from './actions';

const initialState: TSliceWithPromiseFlags<IRoomsSlice> & {
  loader: boolean;
  metaInfo: null | string;
  colCount: string;
  rowCount: string;
  fullscreenMode: null | string;
} = {
  rooms: new Map<string, IGroupRooms>(),
  treeRooms: [],
  selectedRoom: null,
  method: null,
  activeRoomsByClanId: [],
  error: '',
  pending: false,
  loader: false,
  url: '',
  streamKey: '',
  metaInfo: getLStorage(ELStorageKeys.metaInfo),
  colCount: getLStorage(ELStorageKeys.streamsCol) || 'auto',
  rowCount: getLStorage(ELStorageKeys.streamsRow) || 'auto',
  fullscreenMode: getLStorage(ELStorageKeys.useCustomFullscreen) || 'off',
};

const roomsSlice = createSlice({
  name: reducersNames.STREAM,
  initialState,
  reducers: {
    setTreeRooms(state, { payload: { clans, searchParams } }) {
      const showClansParams = searchParams?.get(ESearchParamsStream.ShowClans);

      const hideAllClansParams = searchParams?.get(
        ESearchParamsStream.HideAllClans
      );

      const showAllClansParams = searchParams?.get(
        ESearchParamsStream.ShowAllClans
      );

      const pinnedParams = searchParams?.get(ESearchParamsStream.Pinned);

      const currentParams: {
        clanId: string;
        hiddenRooms?: string[];
        showRooms?: string[];
      }[] = showClansParams ? JSON.parse(showClansParams) : [];

      const newTreeRooms = clans.reduce(
        (treeRooms: ITreeRooms[], clan: IClan) => {
          const activeRooms = state.rooms.get(clan.id)?.active;

          const foundData = state.treeRooms.find(
            (room) => room.clanId === clan.id
          );

          const currentPinnedParams: {
            clanId: string;
            rooms?: string[];
          }[] = pinnedParams ? JSON.parse(pinnedParams) : [];

          const filteredPinnedParams = currentPinnedParams.filter(
            ({ clanId }) => clanId === clan.id
          );

          if (foundData) {
            const rooms = activeRooms
              ? updateTreeRoom(foundData, activeRooms, searchParams)
              : [];

            const foundParams = currentParams.find(
              ({ clanId }) => clanId === foundData.clanId
            );

            const isHiddenRooms = !(
              foundParams?.hiddenRooms || !foundParams?.showRooms
            );

            treeRooms.push({
              clanName: clan.name,
              clanId: String(clan.id),
              rooms,
              isHidden: showAllClansParams
                ? false
                : !foundParams
                ? true
                : isHiddenRooms,
              isPinned:
                !!filteredPinnedParams.find(({ rooms }) => !rooms?.length) ||
                foundData.isPinned,
            });
          } else {
            const isHiddenRooms = !!state.treeRooms.filter((data) =>
              data.rooms.some((room) => room.isHidden)
            ).length;

            const rooms =
              (activeRooms &&
                (isHiddenRooms || hideAllClansParams
                  ? activeRooms.map((room) => ({ ...room, isHidden: true }))
                  : activeRooms)) ||
              [];

            const foundParams = currentParams.find(
              ({ clanId }) => clanId === clan.id
            );

            treeRooms.push({
              clanName: clan.name,
              clanId: String(clan.id),
              rooms,
              isHidden: hideAllClansParams
                ? true
                : currentParams.length && !foundParams
                ? true
                : !!foundData,
              isPinned: !!filteredPinnedParams.find(
                ({ rooms }) => !rooms?.length
              ),
            });
          }

          return treeRooms;
        },
        []
      );

      state.treeRooms = newTreeRooms;
    },
    updateTreeRooms(state, { payload }) {
      state.treeRooms = payload;
    },
    resetHiddenRooms(state) {
      state.rooms.forEach((room) => {
        room.active.forEach((activeRoom) => {
          activeRoom.isHidden = false;
        });
      });
    },
    selectActiveRoomsByClanId(state, { payload: { clanId } }) {
      const rooms = state.rooms.get(clanId)?.active;

      state.activeRoomsByClanId = rooms || [];
    },
    setMethod(state, { payload }) {
      state.method = payload;
    },
    setMetaInfo(state, { payload }) {
      state.metaInfo = payload;
    },
    setRowCount(state, { payload }) {
      state.rowCount = payload;
    },
    setColCount(state, { payload }) {
      state.colCount = payload;
    },
    setFullscreenMode(state, { payload }) {
      state.fullscreenMode = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        getClanRoomsThunk.fulfilled,
        (state, { payload: { clanId, rooms } }) => {
          if (rooms.length) {
            const groupRooms = groupRoomsAdapter(rooms, clanId, state.rooms);

            state.rooms.set(clanId, groupRooms);
          } else {
            state.rooms.delete(clanId);
          }

          state.pending = false;
        }
      )
      .addCase(getClanRoomsThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getClanRoomsThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(getAllRoomsThunk.fulfilled, (state, { payload }) => {
        payload.forEach(({ rooms, clanId }) => {
          if (rooms.length) {
            const groupRooms = groupRoomsAdapter(rooms, clanId, state.rooms);

            state.rooms.set(clanId, groupRooms);
          } else {
            state.rooms.delete(clanId);
          }
        });

        if (state.rooms.size > payload.length) {
          const filteredKeys = Array.from(state.rooms.keys()).filter(
            (key) => !payload.some((value) => value.clanId === key)
          );

          filteredKeys.forEach((key) => {
            state.rooms.delete(key);
          });
        }

        state.pending = false;
      })
      .addCase(getAllRoomsThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getAllRoomsThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(
        createRoomThunk.fulfilled,
        (
          state,
          {
            payload: {
              livekitName,
              roomName,
              numParticipants,
              accountId,
              token,
              src,
              isActive,
              createdAt,
              location,
            },
          }
        ) => {
          state.selectedRoom = {
            livekitName,
            roomName,
            numParticipants,
            accountId,
            clanId: livekitName.split(SEPARATOR)[0],
            src,
            isRecording: false,
            isActive,
            token,
            createdAt,
            location,
          };

          state.pending = false;
          state.error = null;
        }
      )
      .addCase(createRoomThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(createRoomThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(createRoomIngressThunk.fulfilled, (state, { payload }) => {
        if (payload?.stream_key && payload?.url) {
          state.url = payload.url;
          state.streamKey = payload.stream_key;

          state.loader = false;
          state.error = null;
        }
      })
      .addCase(createRoomIngressThunk.pending, (state) => {
        state.loader = true;
        state.error = null;
      })
      .addCase(createRoomIngressThunk.rejected, (state) => {
        state.loader = false;
      })
      .addCase(
        getAllLiveKitTokensThunk.fulfilled,
        (state, { payload: { items } }) => {
          items.forEach(({ clan_id, room_name, token }) => {
            const rooms = state.rooms.get(clan_id);

            if (rooms) {
              const activeRoom = rooms.active.find(
                (room) => room.roomName === room_name
              );

              if (activeRoom) {
                activeRoom.token = token;
              }

              state.rooms.set(clan_id, rooms);
            }
          });

          if (state.activeRoomsByClanId) {
            state.activeRoomsByClanId = Array.from(
              state.rooms.values()
            ).flatMap((room) => room.active);
          }
        }
      )
      .addCase(getAllLiveKitTokensThunk.rejected, (state, { payload }) => {
        const error = payload as AxiosError<{ code: string; message: string }>;

        if (error.response) {
          const { data } = error.response;
          state.error = data.message;
        }
      })
      .addCase(
        getLiveKitTokenThunk.fulfilled,
        (state, { payload: { token, clanId, roomName } }) => {
          const rooms = state.rooms.get(clanId);

          if (rooms) {
            const room = rooms.active.find(
              (item) => item.roomName === roomName
            );

            if (room) {
              room.token = token;
            }

            if (state.activeRoomsByClanId) {
              state.activeRoomsByClanId = rooms.active;
            }

            state.rooms.set(clanId, rooms);
          }
        }
      )
      .addCase(getLiveKitTokenThunk.rejected, (state, { payload }) => {
        const error = payload as AxiosError<{ code: string; message: string }>;

        if (error.response) {
          const { data } = error.response;
          state.error = data.message;
        }
      })
      .addCase(deleteRoomThunk.fulfilled, (state, { payload }) => {
        const { clanId, roomName } = payload as unknown as IDeleteRoomInput;

        if (clanId) {
          const clanRooms = state.rooms.get(clanId);

          if (clanRooms) {
            const { active } = clanRooms;

            state.rooms.set(clanId, {
              ...clanRooms,
              active: active.filter((room) => room.roomName !== roomName),
            });
          }
        }

        state.selectedRoom = null;
      });
  },
});

export const { actions: roomActions, reducer: roomReducer } = roomsSlice;

export * from './selectors';
