import { FC, useState } from 'react';

import { Button, Modal, TextInput } from 'components/ui';

import { getWordDayForm } from '../../utils';

interface DeleteAllAccessesModalProps {
  accessesAmount: number;
  onClose: () => void;
  onSubmit: () => void;
}

export const DeleteAllAccessesModal: FC<DeleteAllAccessesModalProps> = ({
  accessesAmount,
  onClose,
  onSubmit,
}) => {
  const [checkAmount, setCheckAmount] = useState('');

  const wordForm = getWordDayForm(accessesAmount, [
    'доступ',
    'доступа',
    'доступов',
  ]);

  return (
    <Modal onClose={onClose} isBlurred>
      <div className="flex flex-col gap-[24px] max-w-[530px] w-[530px] justify-center border border-solid border-tpg_light rounded-[10px] bg-dark p-[48px]">
        <div className="flex flex-col gap-[12px] items-center">
          <span className="tpg-h4 text-center">
            Вы уверены, что хотите удалить {accessesAmount > 1 ? 'все' : ''}{' '}
            {accessesAmount} {wordForm}?
          </span>
          <span className="tpg-b2 text-tpg_base">
            Вы можете потерять ценные данные.
          </span>
          <span className="tpg-b2 text-tpg_base text-center">
            Введите количество доступов, чтобы подтвердить удаление.
          </span>
          <TextInput
            placeholder="Введите количество"
            inputClassName="bg-ultrablack"
            onChange={(value) => setCheckAmount(value)}
            value={checkAmount}
          />
        </div>
        <div className="flex flex-row gap-[12px]">
          <Button
            onClick={onClose}
            title="Нет, я передумал"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
          <Button
            disabled={+checkAmount !== accessesAmount}
            onClick={onSubmit}
            title="Да, я хочу удалить"
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  );
};
