import { ELStorageKeys } from 'constants/localStorage';
import { useAppDispatch, useAppSelector } from 'hooks';
import { roomActions } from 'store/slices/room';
import { roomSelector } from 'store/slices/room/selectors';

import { setLStorage } from 'utils/helpers/localStorage';

export const ToggleMetaInfo = () => {
  const { metaInfo } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  const handleMetaInfoMenuClick = () => {
    if (metaInfo === 'show') {
      setLStorage([ELStorageKeys.metaInfo, 'hide']);

      dispatch(roomActions.setMetaInfo('hide'));
    } else {
      setLStorage([ELStorageKeys.metaInfo, 'show']);

      dispatch(roomActions.setMetaInfo('show'));
    }
  };

  return (
    <span
      className="cursor-pointer tpg-c1 text-tpg_base hover:text-bright_product transition"
      onClick={handleMetaInfoMenuClick}
    >
      {`${metaInfo === 'show' ? 'Скрыть' : 'Показать'} мета информацию`}
    </span>
  );
};
