import { FC, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';

import { getAuditLogs } from '../../api/audit';
import { NOTHING_WAS_FOUND_MESSAGE } from '../../constants/routes';
import { useBreakPoint } from '../../hooks';
import { auditTitleMap, IAuditLog } from '../../interfaces/audit';
import { getRowDescription } from '../../utils/audit';
import { Loader } from '../ui/Loader';

interface HistoryInfoProps {
  id: string;
  isClan: boolean;
}

export const HistoryInfo: FC<HistoryInfoProps> = ({ id, isClan }) => {
  const breakPoint = useBreakPoint();
  const containerRef = useRef<HTMLDivElement>(null);

  const [logs, setLogs] = useState<IAuditLog[]>([]);
  const [pending, setPending] = useState(false);

  const fetchLogs = async () => {
    setPending(true);

    const filters = [{ id: isClan ? 'clan' : 'users', values: [id] }];

    getAuditLogs({
      filters,
      limit: 50,
    })
      .then(({ data }) => {
        if (data) {
          setLogs(data.result);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch logs', error);
      })
      .finally(() => {
        setPending(false);
      });
  };

  useEffect(() => {
    fetchLogs();
  }, [isClan, id]);

  if (pending)
    return (
      <div className="w-6/12 flex items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div
      ref={containerRef}
      className={cn('w-6/12 min-h-full overflow-auto', {
        'w-full': breakPoint === 'mobile' || breakPoint === 'tablet',
      })}
    >
      <div className="flex flex-row justify-between bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[2]">
        История действий
      </div>
      <div className="flex flex-col gap-[16px] px-[16px] pt-[8px] pb-[16px]">
        {logs.length ? (
          logs.map((log: IAuditLog) => (
            <div key={log.id} className="flex flex-col">
              <div className="flex flex-row items-center gap-[4px] cursor-pointer">
                <span className="text-tpg_base tpg-b2">
                  {format(new Date(log.date), 'dd.MM.yyyy HH:mm')}{' '}
                  {auditTitleMap[log.log_type]}
                </span>
              </div>
              <div
                className="overflow-hidden transition-[height] h-full"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(getRowDescription(log), {
                    ADD_ATTR: ['target'],
                  }),
                }}
              />
            </div>
          ))
        ) : (
          <span className="text-tpg_base tpg-c2">
            {NOTHING_WAS_FOUND_MESSAGE}
          </span>
        )}
      </div>
    </div>
  );
};
