import { Track } from 'livekit-client';

import { useBreakPoint } from './useBreakPoint';

export const LOW = {
  width: 640,
  height: 360,
};

export const MEDIUM = {
  width: 960,
  height: 540,
};

export const HIGH = {
  width: 1280,
  height: 720,
};

export const useQualityControl = (rooms: number): Track.Dimensions => {
  const breakPoint = useBreakPoint(true);

  if (breakPoint === 'mobile') return LOW;

  if (breakPoint === 'tablet') {
    if (rooms === 1) return MEDIUM;

    if (rooms >= 2) return LOW;
  }

  if (breakPoint === 'tablet-landscape') {
    if (rooms === 1) return HIGH;

    if (rooms >= 2) return LOW;
  }

  if (breakPoint === 'desktop') {
    if (rooms === 1) return HIGH;

    if (rooms >= 2) return LOW;
  }

  if (breakPoint === 'monitor') {
    if (rooms === 1) return HIGH;

    if (rooms >= 2 && rooms <= 4) return MEDIUM;

    if (rooms >= 5) return LOW;
  }

  if (breakPoint === 'large-screen') {
    if (rooms <= 4) return HIGH;

    if (rooms >= 5) return LOW;
  }

  return MEDIUM;
};
