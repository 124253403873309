import { createSignal } from '@react-rxjs/utils';
import { merge } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { Event } from './events';

const [event$, setEvent] = createSignal<Event | undefined>();

export const eventMap$ = merge(event$);

const eventDispatcher = (event: Event): void => {
  event.id = uuid();
  setEvent(event);
};

export default Object.freeze({
  dispatch: eventDispatcher,
});
