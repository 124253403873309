import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { ReactComponent as TrashIcon } from 'images/newIcons/trash.svg';

import STable, { STableFetchParams } from 'components/ui/Table/STable';
import { STableCell } from 'components/ui/Table/STableCell';
import { IFilterValue } from 'components/ui/Table/STableHeader';

import { getPossibleAccountsAndClans } from '../../api/audit';
import { deactivateSession, getSessions } from '../../api/sessions';
import { formatTimestamp } from '../../api/utils/timeUtils';
import { IEntity, ISelectOption } from '../../interfaces';
import { IClanPossibleValue } from '../../interfaces/player';
import { IGeoIp, ISession } from '../../interfaces/sessions';
import { convertFilterValueFromSelectOption } from '../../utils';
import { getAccountLink } from '../../utils/accounts';

const getHeadCellsData = (users: ISelectOption[]) => [
  {
    id: 'ip',
    numeric: false,
    disablePadding: false,
    label: 'IP',
  },
  {
    id: 'account',
    numeric: false,
    disablePadding: false,
    label: 'Пользователь',
    possibleValues: users.map((el) => el.label),
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
    possibleValues: ['Активна', 'Неактивна'],
  },
  {
    id: 'geo_ip',
    numeric: false,
    disablePadding: false,
    label: 'Местоположение',
  },
  {
    id: 'user_agent',
    numeric: false,
    disablePadding: false,
    label: 'Устройство',
  },
  {
    id: 'login_at',
    numeric: false,
    disablePadding: false,
    label: 'Вход',
    sortableProperty: 'date',
    dateFilter: true,
  },
  {
    id: 'last_seen',
    numeric: false,
    disablePadding: false,
    label: 'Последнее действие',
    sortableProperty: 'date',
    dateFilter: true,
  },
  {
    id: 'expires_at',
    numeric: false,
    disablePadding: false,
    label: 'Истекает',
    sortableProperty: 'date',
    dateFilter: true,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

export const SessionsTable = () => {
  const [selectedFilters, setSelectedFilters] = useState<IFilterValue[]>([]);
  const [possibleUsers, setPossibleUsers] = useState<ISelectOption[]>([]);

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');

  const fetchRows = useMemo(
    () => async (params: STableFetchParams) => {
      const fetchFilterValues: IFilterValue[] = selectedFilters.map(
        (selectedFilter) => {
          let convertedValues: string[];

          switch (selectedFilter.id) {
            case 'status':
              convertedValues = selectedFilter.values.map((el) =>
                el === 'Активна' ? 'true' : 'false'
              );

              break;
            case 'account':
              convertedValues = convertFilterValueFromSelectOption(
                selectedFilter.values,
                possibleUsers
              );

              break;

            default:
              convertedValues = selectedFilter.values;
          }

          return {
            id: selectedFilter.id,
            values: convertedValues,
          };
        }
      );

      const response = await getSessions({
        offset: params.start,
        limit: params.size,
        filters: fetchFilterValues,
        search: search,
      }).then((r) => r.data);

      return {
        rows: response?.result || [],
        total: response?.total || 0,
      };
    },
    [selectedFilters, search]
  );

  const ENTITIES_HEAD_CELLS = useMemo(
    () => getHeadCellsData(possibleUsers),
    [possibleUsers]
  );

  const handleDeactivateSession = async (sessionId: string) => {
    await deactivateSession(sessionId);
    const copyFilters = [...selectedFilters];
    setSelectedFilters(copyFilters);
  };

  const getRowGeoIp = (geoIp: IGeoIp) =>
    `${geoIp.country} ${geoIp.city} ${geoIp.latitude}, ${geoIp.longitude}`;

  useEffect(() => {
    const fetchPossibleValues = async () => {
      const { data, error } = await getPossibleAccountsAndClans();

      if (data) {
        const users =
          data.find((el: IClanPossibleValue) => el.id === 'users')?.values ||
          [];

        setPossibleUsers(
          users.map((el: IEntity) => ({
            label: el.name,
            value: el.id,
          }))
        );
      }

      if (error) {
        console.error(error);
      }
    };

    fetchPossibleValues();
  }, []);

  return (
    <div className="relative">
      <STable<ISession, string>
        headCells={ENTITIES_HEAD_CELLS}
        rowIdExtractor={(row) => row?.id}
        fetchRows={fetchRows}
        onFiltersChange={setSelectedFilters}
        selectedFilters={selectedFilters}
        isPagination
        renderRowCells={(row, props) => {
          const accountName = DOMPurify.sanitize(
            getAccountLink(
              row.account_id,
              `${row.account_name} (${row.account_login})`
            ),
            {
              ADD_ATTR: ['target'],
            }
          );

          return (
            <>
              <STableCell
                {...props}
                key={`-${row.id}`}
                propName="ip"
                cellType="text"
              >
                {row.ip}
              </STableCell>
              <STableCell
                {...props}
                key={`-${row.id}`}
                propName="account_id"
                cellType="text"
                dangerouslySetInnerHTML={{ __html: accountName }}
              />
              <STableCell
                {...props}
                key={`-${row.id}`}
                propName="active"
                cellType="text"
              >
                {row.active && <span className="text-[#00ff00]">Активна</span>}
                {!row.active && (
                  <span className="text-[#ff0000]">Неактивна</span>
                )}
              </STableCell>
              <STableCell
                {...props}
                key={`-${row.id}`}
                propName="geo_ip"
                cellType="text"
              >
                {row.geo_ip ? getRowGeoIp(row.geo_ip) : 'Не определено'}
              </STableCell>
              <STableCell
                {...props}
                key={`-${row.id}`}
                propName="user_agent"
                cellType="text"
              >
                {row.user_agent}
              </STableCell>
              <STableCell
                {...props}
                key={`-${row.id}`}
                propName="login_at"
                cellType="text"
              >
                {formatTimestamp(row.login_at.toString())}
              </STableCell>
              <STableCell
                {...props}
                key={`-${row.id}`}
                propName="last_seen"
                cellType="text"
              >
                {formatTimestamp(row.last_seen.toString())}
              </STableCell>
              <STableCell
                {...props}
                key={`-${row.id}`}
                propName="last_seen"
                cellType="text"
              >
                {formatTimestamp(row.expires_at.toString())}
              </STableCell>
              <div
                className="group cursor-pointer transition min-w-[100px] flex justify-center items-center h-[153px] border-solid border-b border-[rgba(77,80,100,1)]"
                onClick={() => handleDeactivateSession(row.id)}
              >
                {row.active && (
                  <TrashIcon className="[&>path]:fill-red w-[16px] h-[16px] [&>path]:transition" />
                )}
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
