import { FC, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { useVisibleRecords } from 'hooks/useVisibleRecords';
import { ITag } from 'interfaces';
import { IRecord } from 'interfaces/record';
import { clansSelector } from 'store';
import { recordsActions, recordsSelector } from 'store/slices/records';

import { Card } from 'components/Card';
import { DatePicker } from 'components/DatePicker';
import { DateTag } from 'components/DatePicker/DateTag';
import { MoreButton } from 'components/MoreButton';
import { TagsSection } from 'components/TagsSection';
import { filterRecords, getWordDayForm } from 'utils';

interface RecordsListProps {
  records: IRecord[];
  isFilterByClan?: boolean;
}

const MAX_COUNT = 10;

export const RecordsList: FC<RecordsListProps> = ({
  records,
  isFilterByClan = true,
}) => {
  const { clans } = useAppSelector(clansSelector);

  const { filteredRecords: filteredRecordsState, filterParams } =
    useAppSelector(recordsSelector);

  const [searchParams] = useSearchParams();

  const breakPoint = useBreakPoint();

  const dispatch = useAppDispatch();

  const search = searchParams?.get('search');

  const { selectedPublisherNameTag, selectedClanTag, date } = filterParams;

  const sortedRecords = [...records].sort(
    (a, b) => new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime()
  );

  const filteredRecords = useMemo(
    () =>
      filterRecords(
        sortedRecords,
        search,
        selectedPublisherNameTag,
        selectedClanTag,
        date
      ),
    [sortedRecords, search, selectedPublisherNameTag, selectedClanTag, date]
  );

  useEffect(() => {
    if (
      JSON.stringify(filteredRecordsState) !== JSON.stringify(filteredRecords)
    ) {
      dispatch(recordsActions.setFilteredRecords(filteredRecords));
    }
  }, [filteredRecords, filteredRecordsState]);

  const { visibleRecords, hiddenRecords, showMore } =
    useVisibleRecords(filteredRecords);

  const publisherNameTags = Array.from(
    new Set(sortedRecords.map((record) => record.publisherName).filter(Boolean))
  ).map((tag) => ({ id: tag, title: tag }));

  const clanTags = Array.from(
    new Set(sortedRecords.map((record) => record.clanId).filter(Boolean))
  ).flatMap((tag) => {
    const clan = clans.find((clan) => clan.id === tag);

    return clan ? [{ id: clan.id, title: clan.name }] : [];
  });

  const handlePublisherNameTagChange = (value: ITag[]) => {
    dispatch(
      recordsActions.setFilterParams({
        ...filterParams,
        selectedPublisherNameTag: value,
      })
    );
  };

  const handleClanTagChange = (value: ITag[]) => {
    dispatch(
      recordsActions.setFilterParams({
        ...filterParams,
        selectedClanTag: value,
      })
    );
  };

  const handleDateChange = (value: (string | null)[]) => {
    dispatch(
      recordsActions.setFilterParams({
        ...filterParams,
        date: value,
      })
    );
  };

  const recordWordForm = getWordDayForm(hiddenRecords.length, [
    'запись',
    'записи',
    'записей',
  ]);

  const isMobile = breakPoint === 'mobile' || breakPoint === 'tablet';

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col gap-[16px] pt-[10px] pb-[24px] px-[16px]">
        <div className="w-full flex flex-row items-center justify-between">
          <span>{`${filteredRecords.length} в записи`}</span>
          <div className="h-[24px] flex flex-row items-center gap-[10px]">
            {!isMobile && !!date.length && (
              <DateTag date={date} setDate={handleDateChange} />
            )}
            <DatePicker date={date} onChange={handleDateChange} />
          </div>
        </div>
        {isMobile && !!date.length && (
          <DateTag date={date} setDate={handleDateChange} />
        )}
        {isFilterByClan && (
          <TagsSection
            tags={clanTags}
            onChange={handleClanTagChange}
            selectedTags={selectedClanTag}
          />
        )}
        <TagsSection
          tags={publisherNameTags}
          onChange={handlePublisherNameTagChange}
          selectedTags={selectedPublisherNameTag}
        />
      </div>
      {visibleRecords.length ? (
        <div
          className={cn('grid grid-cols-2 gap-[4px] w-full', {
            '!grid-cols-1': isMobile,
          })}
        >
          {visibleRecords.map(
            ({
              id,
              previewSrc,
              tags,
              publisherName,
              publisherId,
              startedAt,
              endedAt,
              description,
              clanId,
            }) => (
              <Card
                type="records"
                key={id}
                cardId={id}
                src={previewSrc}
                tags={tags}
                clanId={clanId}
                publisherName={publisherName}
                publisherId={publisherId}
                startedAt={startedAt}
                endedAt={endedAt}
                description={description}
              />
            )
          )}
        </div>
      ) : (
        <div className="h-full flex items-center justify-center">
          <span className="tpg-h4 text-tpg_light">Нет ни одной записи</span>
        </div>
      )}
      {!!hiddenRecords.length && (
        <MoreButton
          info={`Доступно ещё ${hiddenRecords.length} ${recordWordForm}`}
          title={`Загрузить ${
            hiddenRecords.length > MAX_COUNT ? MAX_COUNT : hiddenRecords.length
          } ${recordWordForm}`}
          onClick={showMore}
        />
      )}
    </div>
  );
};
