import React, {
  CSSProperties,
  memo,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { ReactComponent as DefaultFilterIcon } from 'images/newIcons/filter.svg';
import { ReactComponent as DefaultFilterActiveIcon } from 'images/newIcons/filter-active.svg';

import { ElementDirectionUnion } from '../../../constants/routes';

import styles from './Filters.module.scss';

interface BaseFilterProps {
  isOpen: boolean;
  isActive: boolean;
  setIsOpen: (isOpen: boolean) => void;
  dropdownContent: ReactNode;
  iconSize?: number;
  dropdownDirection?: ElementDirectionUnion;
  containerStyle?: CSSProperties;
  activeIcon?: React.ReactNode;
  icon?: React.ReactNode;
  containerId?: string;
  scrollContainerRef?: RefObject<HTMLDivElement>;
  tableCellRef?: RefObject<HTMLTableCellElement>;
}

const BaseFilter = ({
  isOpen,
  isActive,
  setIsOpen,
  dropdownContent,
  iconSize = 16,
  containerStyle,
  icon,
  activeIcon,
  containerId,
  scrollContainerRef,
  tableCellRef,
}: BaseFilterProps) => {
  const container = useRef<HTMLElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const iconContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerId) {
      container.current = document.getElementById(containerId);
    }
  }, [containerId]);

  useEffect(() => {
    if (
      !isOpen ||
      !tableCellRef?.current ||
      !scrollContainerRef?.current ||
      !iconContainerRef.current
    )
      return;

    const updatePosition = () => {
      if (!iconContainerRef.current) return;

      const rect = iconContainerRef.current.getBoundingClientRect();

      if (popoverRef.current) {
        const popover = popoverRef.current.getBoundingClientRect();

        popoverRef.current.style.top = `${tableCellRef.current?.offsetHeight}px`;
        popoverRef.current.style.left = `${rect.left - popover.width}px`;
      }
    };

    updatePosition();
    scrollContainerRef.current.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);

    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', updatePosition);
      window.addEventListener('resize', updatePosition);
    };
  }, [
    isOpen,
    scrollContainerRef,
    tableCellRef,
    popoverRef,
    iconContainerRef,
    tableCellRef,
  ]);

  const filterActiveIcon = activeIcon ? (
    activeIcon
  ) : (
    <DefaultFilterActiveIcon width={iconSize} height={iconSize} />
  );

  const filterIcon = icon ? (
    icon
  ) : (
    <DefaultFilterIcon width={iconSize} height={iconSize} />
  );

  return (
    <>
      <div
        ref={iconContainerRef}
        className={`cursor-pointer ${styles['filters-icon']}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen || isActive ? filterActiveIcon : filterIcon}
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={popoverRef}
            className={cn(
              'absolute flex flex-col',
              styles['filters-container']
            )}
            style={containerStyle}
            onClick={(e) => e.stopPropagation()}
          >
            {dropdownContent}
          </div>,
          container.current || document.body
        )}
    </>
  );
};

export default memo(BaseFilter);
