import { FC } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { TDateRange } from 'types';

import { DatePickerDropdown } from 'components/ui/DatePickerDropdown/DatePickerDropdown';

interface IDatePickerProps {
  date: (string | null)[];
  onChange: (value: (string | null)[]) => void;
  title?: string;
}

export const DatePicker: FC<IDatePickerProps> = ({ date, onChange, title }) => {
  const handleDatePicker = (dates: TDateRange) => {
    const formatedDates = new Set(
      dates.map((date) => date && format(date, 'dd.MM.yyyy'))
    );

    onChange(Array.from(formatedDates));
  };

  const formatedDate = date.map((d) => {
    if (d) {
      const [day, month, year] = d.split('.').map(Number);

      return new Date(year, month - 1, day);
    }

    return null;
  }) as TDateRange;

  return (
    <div className="flex flex-row justify-between items-center">
      {title && <span className="tpg-h4">{title}</span>}
      <DatePickerDropdown
        value={formatedDate}
        onValueChange={handleDatePicker}
        className="mt-5 right-0"
        iconClassName={cn(
          'cursor-pointer',
          date.length && '[&>path]:fill-bright_product'
        )}
      />
    </div>
  );
};
