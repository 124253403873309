import { FC } from 'react';
import cn from 'classnames';
import { HEADER_HEIGHT, PADDING } from 'constants/common';
import { useWindowSize } from 'hooks';

interface EmtyScreenProps {
  text: string;
  isFullscreenOpen?: boolean;
  className?: string;
  height?: number;
}

const GAP = 5;

export const EmtyScreen: FC<EmtyScreenProps> = ({
  text,
  isFullscreenOpen,
  className,
  height: heightProps,
}) => {
  const { height } = useWindowSize();

  const heightScreen =
    heightProps ||
    `${height - (isFullscreenOpen ? PADDING : HEADER_HEIGHT + GAP)}px`;

  return (
    <div
      className={cn(
        'flex justify-center items-center w-full bg-light rounded-[10px]',
        className
      )}
      style={{ height: heightScreen }}
    >
      <span className="tpg-h4 text-tpg_light">{text}</span>
    </div>
  );
};
