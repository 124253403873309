import { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as RightArrow } from 'images/newIcons/rightArrow.svg';

interface INavigationProps {
  text: string;
  isDisableLeftButton: boolean;
  isDisableRightButton: boolean;
  onClickRight: () => void;
  onClickLeft: () => void;
}

export const Navigation: FC<INavigationProps> = ({
  text,
  isDisableRightButton,
  isDisableLeftButton,
  onClickRight,
  onClickLeft,
}) => (
  <div className="flex flex-row items-center justify-center gap-[2px] absolute left-1/2 -translate-x-1/2">
    <div className="group flex items-center" onClick={onClickLeft}>
      <RightArrow
        className={cn(
          'w-[20px] h-[20px] rotate-180 cursor-pointer transform scale-125 group-hover:[&>path]:fill-bright_product [&>path]:fill-tpg_base [&>path]:transition',
          {
            'group-hover:[&>path]:fill-tpg_light [&>path]:fill-tpg_light pointer-events-none':
              isDisableLeftButton,
          }
        )}
      />
    </div>
    <span className="tpg-c2 text-tpg_base">{text}</span>
    <div
      className="group flex items-center hover:[&>svg>path]:fill-bright_product"
      onClick={onClickRight}
    >
      <RightArrow
        className={cn(
          'w-[20px] h-[20px] cursor-pointer transform scale-125 group-hover:[&>path]:fill-bright_product [&>path]:fill-tpg_base [&>path]:transition',
          {
            'group-hover:[&>path]:fill-tpg_light [&>path]:fill-tpg_light pointer-events-none':
              isDisableRightButton,
          }
        )}
      />
    </div>
  </div>
);
