import { errorMessages, successMessages } from 'constants/messages';
import axios from 'services/axios';

import {
  IAllRoomsResponse,
  IClanRoomsResponse,
  ICreateIngressResponse,
  IDeleteRoomInput,
  IGetAllLiveKitTokens,
  IGetLiveKitTokenInput,
  IGetRoomResponse,
  ILiveKitTokenResponse,
  ISignalRoomInput,
  ITokenResponse,
  IUpdateRoomLocationRequestParams,
  TCreateRoomInput,
} from './types/room';
import { requestHandler } from './utils/requestHandler';

export const getClanRooms = requestHandler((clanId: string) =>
  axios.get<IClanRoomsResponse>(`/clans/${clanId}/rooms`)
);

export const getAllRooms = requestHandler(() =>
  axios.get<IAllRoomsResponse>(`/rooms`)
);

export const updateRoomLocation = requestHandler(
  ({ clanId, roomName, lat, lon, zoom }: IUpdateRoomLocationRequestParams) =>
    axios.patch<void>(`/clans/${clanId}/rooms/${roomName}/location`, {
      location: {
        lat,
        lon,
      },
      zoom,
    }),
  {
    defaultErrorMessage: errorMessages.UPDATE_ROOM_LOCATION,
    defaultSuccessMessage: successMessages.UPDATE_ROOM_LOCATION,
  }
);

export const getRoomParticipants = requestHandler(
  ({ clanId, roomName }: { clanId: string; roomName: string }) =>
    axios.get(`/clans/${clanId}/rooms/${roomName}/participants`)
);

export const getLiveKitToken = requestHandler(
  ({ clanId, roomName }: IGetLiveKitTokenInput) =>
    axios.post<ITokenResponse>(
      `/clans/${clanId}/rooms/${roomName}/exchange-token`
    )
);

export const getAllLiveKitTokens = requestHandler(
  (items: IGetAllLiveKitTokens[]) =>
    axios.post<{ items: ILiveKitTokenResponse[] }>('/batch-exchange-token', {
      items,
    })
);

export const createRoom = requestHandler(
  ({ roomName, clanId }: TCreateRoomInput) =>
    axios.post<IGetRoomResponse>(`/clans/${clanId}/rooms/${roomName}`),
  {
    defaultErrorMessage: errorMessages.CREATE_ROOM,
  }
);

export const setRoomNative = requestHandler(
  ({ roomName, clanId, isNative }: TCreateRoomInput) =>
    axios.patch<IGetRoomResponse>(`/clans/${clanId}/rooms/${roomName}`, {
      is_native: isNative,
    }),
  {
    defaultErrorMessage: errorMessages.UPDATE_ROOM,
  }
);

export const createRoomIngress = requestHandler(
  ({ roomName, clanId }: TCreateRoomInput) =>
    axios.post<ICreateIngressResponse>(
      `/clans/${clanId}/rooms/${roomName}/ingress`,
      {
        type: 'RTMP_INPUT',
      }
    )
);

export const getRecordingSignal = requestHandler(
  ({ roomName, clanId }: ISignalRoomInput) =>
    axios.post(`/clans/${clanId}/rooms/${roomName}/wait`)
);

export const deleteRoom = requestHandler(
  ({ roomName, clanId, params }: IDeleteRoomInput) =>
    axios.delete(`/clans/${clanId}/rooms/${roomName}`, {
      data: params,
    }),
  {
    defaultErrorMessage: errorMessages.DELETE_ROOM,
  }
);

export const deleteRoomIngress = requestHandler(
  ({ roomName, clanId }: Omit<IDeleteRoomInput, 'params'>) =>
    axios.delete(`/clans/${clanId}/rooms/${roomName}/ingress`),
  {
    defaultErrorMessage: errorMessages.DELETE_ROOM,
  }
);
