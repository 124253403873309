import { FC } from 'react';
import cn from 'classnames';
import { ELStorageKeys } from 'constants/localStorage';
import { ReactComponent as FullSizeIcon } from 'images/newIcons/fullSize.svg';

import { getLStorage } from 'utils/helpers/localStorage';

interface FullScreenButtonProps {
  onClick: (flag: boolean) => void;
  className?: string;
  isShowIcon?: boolean;
}

export const FullScreenButton: FC<FullScreenButtonProps> = ({
  onClick,
  className,
  isShowIcon = true,
}) => {
  const fullscreenMode = getLStorage(ELStorageKeys.useCustomFullscreen);

  const handleClickButton = () => {
    if (
      !document.fullscreenElement &&
      (!fullscreenMode || fullscreenMode === 'off')
    ) {
      onClick(true);

      if (document.documentElement?.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    }

    if (!document.fullscreenElement && fullscreenMode === 'on') {
      onClick(true);
    }
  };

  return (
    <div
      className={cn(
        'h-[20px] gap-[4px] flex flex-row group cursor-pointer justify-center items-center min-w-[145px] hover:text-bright_product transition',
        className
      )}
      onClick={handleClickButton}
    >
      <span className="tpg-c2">На полный экран</span>
      {isShowIcon && (
        <FullSizeIcon className="group-hover:[&>g>path]:fill-bright_product w-[18px] h-[18px] [&>g>path]:transition" />
      )}
    </div>
  );
};
