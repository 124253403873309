import { useEffect } from 'react';
import { ELStorageKeys } from 'constants/localStorage';
import { useAppDispatch, useBreakPoint } from 'hooks';
import { roomActions } from 'store/slices/room';

import { StreamsCountSelect } from 'components/Header/StreamsCountSelect';
import {
  clearLStorageMany,
  getLStorage,
  setLStorage,
} from 'utils/helpers/localStorage';

export const StreamControls = () => {
  const dispatch = useAppDispatch();

  const breakPoint = useBreakPoint();

  const streamsCol = getLStorage(ELStorageKeys.streamsCol);
  const streamsRow = getLStorage(ELStorageKeys.streamsRow);

  useEffect(() => {
    if (breakPoint === 'mobile') {
      clearLStorageMany(
        ...Object.values([ELStorageKeys.streamsRow, ELStorageKeys.streamsCol])
      );

      dispatch(roomActions.setColCount('auto'));
      dispatch(roomActions.setRowCount('auto'));
    }
  }, [breakPoint]);

  useEffect(() => {
    if (streamsCol) {
      dispatch(roomActions.setColCount(streamsCol));
    }

    if (streamsRow) {
      dispatch(roomActions.setRowCount(streamsRow));
    }
  }, [streamsCol, streamsRow]);

  const handleRowCountSelect = (count: string) => {
    dispatch(roomActions.setRowCount(count));

    setLStorage([ELStorageKeys.streamsRow, count]);
  };

  const handleColCountSelect = (count: string) => {
    dispatch(roomActions.setColCount(count));

    setLStorage([ELStorageKeys.streamsCol, count]);
  };

  return (
    <>
      <StreamsCountSelect
        label="Макс кол-во стримов в строке"
        count={6}
        onSelect={handleColCountSelect}
        selectedOption={
          streamsCol
            ? {
                label: streamsCol === 'auto' ? 'Авто' : streamsCol,
                value: streamsCol,
              }
            : undefined
        }
      />
      <StreamsCountSelect
        label="Макс кол-во строк на одном экране"
        count={6}
        onSelect={handleRowCountSelect}
        selectedOption={
          streamsRow
            ? {
                label: streamsRow === 'auto' ? 'Авто' : streamsRow,
                value: streamsRow,
              }
            : undefined
        }
      />
    </>
  );
};
