import { FC, useState } from 'react';
import DOMPurify from 'dompurify';
import { groupBy } from 'lodash';

import { formatTimestamp } from '../../api/utils/timeUtils';
import { IListRoomStats } from '../../interfaces';
import { IClan } from '../../interfaces/clan';
import { getAccountLink, getClanLink } from '../../utils/accounts';

interface RoomStatProps {
  idx: number;
  roomStats: IListRoomStats;
  clans: IClan[];
}

export const RoomStat: FC<RoomStatProps> = ({ idx, roomStats, clans }) => {
  const [isOpened, setIsOpened] = useState(false);

  const firstSourceParticipant = roomStats.participants.find(
    (p) => p.is_publisher
  );

  const nonSourceParticipants = roomStats.participants.filter(
    (p) => p !== firstSourceParticipant
  );

  const uniqueParticipantsGrouped = groupBy(nonSourceParticipants, (p) => p.id);

  const renderClanHyperlink = (clanId: string) => {
    const matchingClanName = clans.find((c) => c.id === clanId)?.name;

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            ` [Подразделение: 
      ${getClanLink(clanId, matchingClanName || '????')}]`,
            {
              ADD_ATTR: ['target'],
            }
          ),
        }}
      />
    );
  };

  return (
    <div className="border border-solid rounded-[10px] border-dark_product p-4">
      <div
        className="cursor-pointer select-none"
        onClick={() => setIsOpened(!isOpened)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              `${idx + 1}. [ ${isOpened ? '-' : '+'} ] ` +
                getAccountLink(
                  roomStats.room.publisher_id,
                  roomStats.room.publisher_name
                ),
              {
                ADD_ATTR: ['target'],
              }
            ),
          }}
        />
        {renderClanHyperlink(roomStats.room.clan_id)}
        <span> от {formatTimestamp(roomStats.room.created_at)}</span>
        <span className="ml-2 ">
          Версия приложения:{' '}
          <span className="text-red">{roomStats.room.app_version}</span>
        </span>
        <span className="ml-3">
          Участников:{' '}
          <span className="text-red">{roomStats.participants.length}</span>
        </span>
      </div>
      {isOpened && (
        <div>
          {firstSourceParticipant && (
            <div className="mt-2">
              <span>Источник:</span>
              <span
                className="ml-2"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    getAccountLink(
                      firstSourceParticipant.id,
                      firstSourceParticipant.name
                    ),
                    {
                      ADD_ATTR: ['target'],
                    }
                  ),
                }}
              />
              <span className="ml-2">
                Статус:
                <span className="text-red">
                  {' '}
                  {firstSourceParticipant.state}
                </span>
              </span>
              <span className="ml-2 text-tpg_base underline">
                {JSON.stringify(firstSourceParticipant.track_infos)}
              </span>
            </div>
          )}
          <div className="mt-2">
            Участники:
            <div className="mt-2">
              {Object.entries(uniqueParticipantsGrouped).map(
                ([id, participants]) => (
                  <div key={id}>
                    {participants.map((participant, idx) => (
                      <div key={idx}>
                        {idx + 1}.{' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              getAccountLink(participant.id, participant.name),
                              {
                                ADD_ATTR: ['target'],
                              }
                            ),
                          }}
                        />{' '}
                        {renderClanHyperlink(participant.clan_id)} от{' '}
                        {formatTimestamp(participant.timestamp)}. Статус:{' '}
                        <span className="text-red">{participant.state}</span>
                        {participant.track_infos
                          ? JSON.stringify(participant.track_infos)
                          : ''}
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
