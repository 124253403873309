import { FC, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getClansThunk } from 'store/slices/clan/actions';
import { roomSelector } from 'store/slices/room';

import { AllClansStreamsPanel } from './AllClansStreamsPanel';

interface StreamsPanelScreenProps {
  isFullscreenOpen: boolean;
  onFullscreenClose: (flag: boolean) => void;
}

export const StreamsPanelScreen: FC<StreamsPanelScreenProps> = ({
  isFullscreenOpen,
  onFullscreenClose,
}) => {
  const dispatch = useAppDispatch();
  const { treeRooms, rooms: roomsData } = useAppSelector(roomSelector);

  useEffect(() => {
    dispatch(getClansThunk());

    const closeFullscreen = () => {
      onFullscreenClose?.(false);

      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen();
      }
    };

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        onFullscreenClose?.(false);
      }
    };

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        document.dispatchEvent(new Event('customExitFullscreen'));
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('customExitFullscreen', closeFullscreen);
    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('customExitFullscreen', closeFullscreen);
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const handleFullscreenExit = useCallback(() => {
    const event = new Event('customExitFullscreen');
    document.dispatchEvent(event);
  }, []);

  return (
    <AllClansStreamsPanel
      isFullscreenOpen={isFullscreenOpen}
      treeRooms={treeRooms}
      rooms={roomsData}
      onExitFullscreen={handleFullscreenExit}
    />
  );
};
