import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs_plugin_utc from 'dayjs/plugin/utc';
import { TDateRange } from 'types';

import { DATE_FORMAT, MILLISECONDS } from '../../constants/map';

dayjs.extend(duration);
dayjs.extend(dayjs_plugin_utc);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

export const getFormattedDate = (
  date: string | null | undefined | Date,
  format = 'DD.MM.YY HH:mm',
  keepUTCLocalTime = false
) => {
  if (date) {
    return dayjs(date).utc(keepUTCLocalTime).format(format);
  }

  return null;
};

export const isBetweenDate = (range: TDateRange | null, date?: string) => {
  if (!date || !range || (range && !range[0])) return false;

  let startDate = null;
  let endDate = null;

  if (range[0]) {
    startDate = dayjs(range[0]).format(DATE_FORMAT);
  }

  if (range[1]) {
    endDate = dayjs(range[1]).format(DATE_FORMAT);
  }

  return dayjs(date).isBetween(startDate, endDate, 'day', '[]');
};

export const formatDate = (date: Date) => dayjs(date).format(DATE_FORMAT);

export const getDurationString = (count: number) =>
  dayjs.duration(count * MILLISECONDS).format('HH:mm:ss');
