import { FC } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { ReactComponent as RollbackIcon } from 'images/newIcons/rollback.svg';

interface BackButtonProps {
  path?: string;
  title?: string;
}

export const BackButton: FC<BackButtonProps> = ({ path, title }) => {
  const navigate = useNavigate();

  return (
    <div
      className="group flex flex-row gap-[4px] items-center pl-[6px] cursor-pointer hover:text-bright_product transition"
      onClick={() => navigate((path ?? -1) as To)}
    >
      <span className="tpg-c2">{title || 'Вернуться'}</span>
      <RollbackIcon className="w-[20px] h-[20px] group-hover:[&>path]:fill-bright_product [&>path]:transition" />
    </div>
  );
};
