import { FC, MouseEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatTimestamp } from 'api/utils/timeUtils';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { intervalToDuration } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ReactComponent as EllipsisIcon } from 'images/newIcons/ellipsis.svg';
import { ITag } from 'interfaces';
import { authSelector } from 'store';
import { isRoot, rolesValuesMap } from 'types/player';

import { DeleteModal } from 'components/DeleteModal';
import { TagsSection } from 'components/TagsSection';
import { copyToClipboard, formatTime } from 'utils';

import { deleteRecordThunk } from '../../store/slices/records/actions';

type CardProps = {
  type: 'records' | 'stream';
  cardId: string;
  clanId: string;
  src?: string;
  className?: string;
  host?: string;
  tags?: string[];
  publisherName?: string;
  publisherId?: string;
  startedAt?: string;
  endedAt?: string;
  description?: string;
};

export const Card: FC<CardProps> = ({
  cardId,
  src,
  tags,
  type,
  className,
  clanId,
  publisherName,
  publisherId,
  startedAt,
  endedAt,
}) => {
  const [isOpenControllerModal, setOpenControllerModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const { roles, id: accountId } = useAppSelector(authSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  const highPriorityRoles = roles.filter(
    (role) =>
      role.role_name === EUserRole.ADMIN || role.role_name === EUserRole.ROOT
  );

  const isAdminForClan = !!highPriorityRoles.find(
    (role) => role.clan_id === clanId
  );

  const isAccessToEdit =
    isRoot(accountId) ||
    publisherId === accountId ||
    (isAdminForClan &&
      publisherId !== rolesValuesMap.root &&
      accountId !== rolesValuesMap.root);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    navigate(`/${type}/${cardId}`, {
      state: { clanId },
    });
  };

  const handleDelete = async () => {
    await dispatch(deleteRecordThunk(cardId));

    setOpenDeleteModal(false);
  };

  const handleOpenModal = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpenControllerModal(!isOpenControllerModal);
  };

  const handleSaveRecord = () => {
    setOpenControllerModal(false);
  };

  const handleResume = () => {
    setOpenControllerModal(false);
  };

  const duration =
    startedAt &&
    endedAt &&
    intervalToDuration({ start: new Date(startedAt), end: new Date(endedAt) });

  const cardTags: ITag[] =
    tags?.flatMap((tag) =>
      tag
        ? {
            id: tag,
            title: tag,
          }
        : []
    ) || [];

  return (
    <div
      className={cn(
        'relative aspect-[1.8] cursor-pointer border-solid border-tpg_light border-[1px] rounded-[10px] hover:[&>div]:after:opacity-0 [&>div]:after:transition-opacity',
        className
      )}
      ref={containerRef}
      onClick={handleClick}
    >
      <div
        className="w-full h-full after:content-[''] after:absolute after:top-0 after:left-0 after:w-full 
        after:h-full after:rounded-[10px] after:bg-[linear-gradient(180deg,_#161824,_#17192538,_#161824)]"
      >
        {src && (
          <img
            className="rounded-[10px] w-full h-full object-cover"
            src={src}
            alt=""
            onError={(e) => e.currentTarget.classList.add('hidden')}
          />
        )}
      </div>
      <div className="absolute flex flex-row items-end justify-between w-full gap-[4px] tpg-b2 px-[16px] top-[12px] pointer-events-none">
        {duration && (
          <span className="text-tpg_base tpg-c1">
            {duration ? formatTime(duration) : '00:00:00'}
          </span>
        )}
        {startedAt && (
          <span className="tpg-c1">{formatTimestamp(startedAt)}</span>
        )}
      </div>
      {!!cardTags?.length && (
        <div className="w-full px-[8px] absolute bottom-[8px]  pointer-events-none">
          <TagsSection
            tags={cardTags}
            textButton="ещё"
            classNameTag="!bg-ultrablack !px-[12px] !py-[5px]"
          />
        </div>
      )}
      {publisherName && (
        <div className="absolute left-[12px] bottom-[10px] flex px-[12px] bg-ultrablack rounded-[5px]">
          <span className="tpg-c2">{publisherName}</span>
        </div>
      )}
      {type === 'records' && (
        <div
          className="absolute right-[16px] bottom-[10px] group cursor-pointer flex items-center justify-center transition"
          onClick={handleOpenModal}
        >
          <EllipsisIcon className="group-hover:[&>circle]:fill-bright_product [&>circle]:transition" />
          {isOpenControllerModal && (
            <div className="absolute bottom-[15px] right-0 z-[1] py-[6px] w-[135px] flex flex-col rounded-[10px] bg-ultrablack border border-solid border-tpg_light">
              <span
                className="cursor-pointer tpg-c2 px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
                onClick={() =>
                  copyToClipboard(
                    `${window.location.origin}/records/${cardId}`,
                    'Ссылка скопирована'
                  )
                }
              >
                Поделиться
              </span>
              <span
                className="cursor-pointer tpg-c2 px-[16px] whitespace-nowrap text-tpg_base hover:text-bright_product"
                onClick={() => setOpenDeleteModal(true)}
              >
                Удалить
              </span>
            </div>
          )}
        </div>
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          title="Вы уверены, что хотите удалить запись?"
          onClose={() => setOpenDeleteModal(false)}
          onRemove={handleDelete}
        />
      )}
    </div>
  );
};
