import { useEffect, useMemo, useState } from 'react';
import { IRecord } from 'interfaces/record';

export const useVisibleRecords = (records: IRecord[], count = 10) => {
  const [visibleCount, setVisibleCount] = useState(count);

  useEffect(() => {
    setVisibleCount(count);
  }, [records, count]);

  const visibleRecords = useMemo(
    () => records.slice(0, visibleCount),
    [records, visibleCount]
  );

  const hiddenRecords = useMemo(
    () => records.slice(visibleCount),
    [records, visibleCount]
  );

  const showMore = () => {
    setVisibleCount((prev) => Math.min(prev + count, records.length));
  };

  return { visibleRecords, hiddenRecords, showMore };
};
