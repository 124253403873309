import { IRecordResponse } from 'api/types/record';
import { IRecord } from 'interfaces/record';

export const recordAdapter = ({
  id,
  clan_id,
  clan_name,
  room,
  publisher_id,
  publisher_name,
  started_at,
  ended_at,
  url,
  description,
  tags,
  thumbnail_url,
}: IRecordResponse): IRecord => ({
  id,
  room,
  clanId: clan_id,
  clanName: clan_name,
  startedAt: started_at,
  endedAt: ended_at,
  publisherId: publisher_id,
  publisherName: publisher_name,
  recordSrc: url,
  previewSrc: thumbnail_url,
  description,
  tags,
});

export const recordsAdapter = (records: IRecordResponse[]) =>
  records.map(recordAdapter);

export const recordsByClansAdapter = (records: IRecordResponse[]) => {
  const recordsMap: Record<string, IRecord[]> = {};

  records.forEach((record) => {
    recordsMap[record.clan_id] = recordsMap[record.clan_id]
      ? [...recordsMap[record.clan_id], recordAdapter(record)]
      : [recordAdapter(record)];
  });

  return recordsMap;
};
