import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateAccount,
  updateAccountRole,
  updateAccountStreamerId,
} from 'api/account';
import { changeEmail, changePassword } from 'api/auth';
import {
  createClan,
  deleteAllClansAccesses,
  deleteClan,
  deleteClanMember,
  getClan,
  getClanMembers,
  getClans,
  setBulkClanMembers,
  setClanMember,
  updateClan,
} from 'api/clans';
import {
  ICreateClanThunkData,
  TSetBulkClanMembersInput,
  TSetClanMemberInput,
} from 'api/types/clan';
import { AxiosError, AxiosResponse } from 'axios';
import { successMessages } from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { IAccountRole, IOption, ThunkAPI } from 'interfaces';
import { IClan } from 'interfaces/clan';
import { IPlayer } from 'interfaces/player';
import { TClanResponse } from 'types/clan';
import { TMember } from 'types/clan';
import { rolesReverseMap } from 'types/player';

import { createThunk, notify } from 'utils';

import { getAccountsThunk, registerWithClanThunk } from '../player/actions';

const getUrls = (
  results: PromiseSettledResult<AxiosResponse<any, any>>[],
  status: 'fullfiled' | 'rejected'
): { url: string; error?: AxiosError<{ code: string; message: string }> }[] =>
  results.map((result: any) =>
    status === 'fullfiled'
      ? { url: result.value.config.url.split('/')[1] }
      : { url: result.reason.config.url.split('/')[1], error: result.reason }
  );

export const getClansThunk = createThunk<TClanResponse[]>(
  `${reducersNames.CLAN}/${asyncActionsNames.GET_CLANS}`,
  getClans
);

export const getClanThunk = createThunk<TClanResponse, string>(
  `${reducersNames.CLAN}/${asyncActionsNames.GET_CLAN}`,
  getClan
);

export const createClanThunk = createAsyncThunk<ThunkAPI, ICreateClanThunkData>(
  `${reducersNames.CLAN}/${asyncActionsNames.CREATE_CLAN}`,
  async (request, thunkAPI) => {
    const { clan, players } = request;

    const { coordinates, admin_id, name, work_area, require_2fa_for_view } =
      clan;

    const { data, error } = await createClan({
      // coordinates,
      admin_id,
      name,
      work_area,
      require_2fa_for_view,
    });

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }

    await Promise.all(
      players.map((player) =>
        thunkAPI.dispatch(
          registerWithClanThunk({
            clanId: data.id,
            password: player.password,
            name: player.name,
            roleId: player.role,
            email: player.email,
            isAddedFromClan: true,
          })
        )
      )
    );

    await thunkAPI.dispatch(getClansThunk());

    return data;
  }
);

export const updateClanThunk = createThunk<IClan, IClan>(
  `${reducersNames.CLAN}/${asyncActionsNames.UPDATE_CLAN}`,
  updateClan
);

export const deleteClanThunk = createAsyncThunk<string, string>(
  `${reducersNames.CLAN}/${asyncActionsNames.DELETE_CLAN}`,
  async (request, thunkAPI) => {
    const { data, error } = await deleteClan(request);

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }

    await thunkAPI.dispatch(getClansThunk());

    return request;
  }
);

export const setClanMemberThunk = createAsyncThunk<
  AxiosResponse<void, void>,
  TSetClanMemberInput
>(
  `${reducersNames.CLAN}/${asyncActionsNames.SET_CLAN_MEMBER}`,
  async (request, thunkAPI) => {
    const { clanId, memberId, roleId, streamerId } = request;

    const { data, error } = await setClanMember({
      clanId,
      memberId,
      roleId,
      streamerId,
    });

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }

    return data;
  }
);

export const setBulkClanMembersThunk = createAsyncThunk<
  AxiosResponse<void, void>,
  TSetBulkClanMembersInput
>(
  `${reducersNames.CLAN}/${asyncActionsNames.SET_BULK_CLAN_MEMBERS}`,
  async (request, thunkAPI) => {
    const { data, error } = await setBulkClanMembers(request);

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }

    return data;
  }
);

export const deleteClanMemberThunk = createAsyncThunk<
  AxiosResponse<any, any>,
  Omit<TSetClanMemberInput, 'roleId'> & { isAddedFromAccount?: boolean }
>(
  `${reducersNames.CLAN}/${asyncActionsNames.DELETE_CLAN_MEMBER}`,
  async (request, thunkAPI) => {
    const { clanId, memberId, isAddedFromAccount } = request;

    const { data, error } = await deleteClanMember({
      clanId,
      memberId,
    });

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }

    if (isAddedFromAccount) {
      thunkAPI.dispatch(getAccountsThunk());

      notify.success(successMessages.DELETE_ACCESS);
    }

    return data;
  }
);

export const deleteAllClansAccessesThunk = createAsyncThunk<
  AxiosResponse<any, any>,
  string
>(
  `${reducersNames.CLAN}/${asyncActionsNames.DELETE_MEMBER_ACCESSES}`,
  async (memberId, thunkAPI) => {
    const { data, error } = await deleteAllClansAccesses(memberId);

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }

    thunkAPI.dispatch(getAccountsThunk());

    return data;
  }
);

export const getClanMembersThunk = createThunk<IPlayer[], string>(
  `${reducersNames.CLAN}/${asyncActionsNames.GET_CLAN_MEMBERS}`,
  getClanMembers
);

export const updateClanMemberThunk = createAsyncThunk<
  Partial<IPlayer>,
  Omit<TMember, 'roles'> & {
    originClan?: IOption;
    roles?: IAccountRole[];
    clanId?: string;
    newRoleId?: string;
  }
>(
  `${reducersNames.CLAN}/${asyncActionsNames.UPDATE_CLAN_MEMBER}`,
  async (request, thunkAPI) => {
    const {
      id,
      name,
      roles,
      password,
      external_id,
      clanId,
      newRoleId,
      originClan,
      streamer_id,
    } = request;

    const updateRequests = [];
    const errorResults = [];
    const successResults = [];

    let newPlayerData: TMember & {
      originClanId?: string;
      originClanName?: string;
    } = { id };

    if (password) {
      try {
        await changePassword({
          id: String(id),
          password: password,
          password2: password,
        });

        newPlayerData = { ...newPlayerData, password };

        successResults.push({ status: 'fulfilled', value: 'password' });
      } catch (error) {
        errorResults.push({ status: 'rejected', value: 'password' });
      }

      updateRequests.push('password');
    }

    if (streamer_id) {
      await updateAccountStreamerId({
        account_id: id,
        clan_id: String(clanId),
        streamer_id,
      });
    }

    if (external_id && password) {
      try {
        await changeEmail({
          id,
          email: external_id,
          current_password: password,
        });

        newPlayerData = {
          ...newPlayerData,
          password,
          external_id,
        };

        successResults.push({ status: 'fulfilled', value: 'email' });
      } catch (error) {
        errorResults.push({ status: 'rejected', value: 'email' });
      }

      updateRequests.push('email');
    }

    if (newRoleId && clanId) {
      try {
        await updateAccountRole({
          account_id: String(id),
          clan_id: String(clanId),
          role_id: newRoleId,
          streamer_id,
        });

        const convertedData = roles?.reduce((roles: IAccountRole[], role) => {
          roles.push(
            role.clan_id === clanId
              ? {
                  ...role,
                  role_id: newRoleId,
                  role_name: rolesReverseMap[newRoleId],
                }
              : role
          );

          return roles;
        }, []);

        newPlayerData = {
          ...newPlayerData,
          roles: convertedData,
        };

        successResults.push({ status: 'fulfilled', value: 'role' });
      } catch (error) {
        errorResults.push({ status: 'rejected', value: 'role' });
      }

      updateRequests.push('role');
    }

    if (name || originClan) {
      try {
        await updateAccount({
          id,
          name,
          originClanId: originClan?.value,
          originClanName: originClan?.label,
        });

        newPlayerData = {
          ...newPlayerData,
          name: name || newPlayerData.name,
          originClanId: originClan?.value || newPlayerData.originClanId,
          originClanName: originClan?.label || newPlayerData.originClanName,
        };

        successResults.push({ status: 'fulfilled', value: 'name' });
      } catch (error) {
        errorResults.push({ status: 'rejected', value: 'name' });
      }

      updateRequests.push('name');
    }

    if (successResults.length === updateRequests.length && clanId) {
      thunkAPI.dispatch(getClanThunk(String(clanId)));
    }

    return errorResults.length
      ? thunkAPI.rejectWithValue(errorResults)
      : newPlayerData;
  }
);
