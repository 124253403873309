import { EUserRole } from './profile';
import { appRoutes } from './routes';

const baseNavs = [
  {
    link: appRoutes.STREAMS,
    title: 'Эфир',
  },
  {
    link: appRoutes.RECORDS,
    title: 'Записи',
  },
  {
    link: appRoutes.CONSUMER_MAP,
    title: 'Карта',
  },
];

const adminNavs = [
  {
    link: appRoutes.ADMIN_DEPARTMENTS,
    title: 'Подразделения',
  },
  {
    link: appRoutes.ADMIN_USERS,
    title: 'Пользователи',
  },
];

const navigatorNavs = [
  {
    link: appRoutes.STREAMS,
    title: 'Эфир',
  },
  {
    link: appRoutes.NAVIGATOR_MAP,
    title: 'Карта',
  },
];

const rootNavs = [
  {
    link: appRoutes.AUDIT,
    title: 'Аудит',
  },
  {
    link: appRoutes.SESSIONS,
    title: 'Сессии',
  },
];

export const navs = {
  [EUserRole.MODERATOR]: [...baseNavs],
  [EUserRole.USER]: [...baseNavs],
  [EUserRole.NAVIGATOR]: [...navigatorNavs],
  [EUserRole.ADMIN]: [...baseNavs, ...adminNavs],
  [EUserRole.ADMIN_WITH_2FA]: [...baseNavs, ...adminNavs, ...rootNavs],
  [EUserRole.ROOT]: [...baseNavs, ...adminNavs, ...rootNavs],
};
