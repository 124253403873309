import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccounts, getAccountsClans } from 'api/account';
import { setClanMember } from 'api/clans';
import { register } from 'api/register';
import { TRegisterRequest } from 'api/types/account';
import { TSetClanMemberInput } from 'api/types/clan';
import { AxiosResponse } from 'axios';
import { successMessages } from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { ThunkAPI } from 'interfaces';
import {
  IClanPlayer,
  IListAccountsResponse,
  IListParams,
  IPlayerClanResponse,
} from 'interfaces/player';

import { createThunk, notify } from 'utils';

import { EUserRole, roleID } from '../../../constants/profile';
import { getClanMembersThunk, setClanMemberThunk } from '../clan/actions';

export const registerThunk = createAsyncThunk<
  AxiosResponse<any, any>,
  TRegisterRequest & { accesses?: IClanPlayer[] }
>(
  `${reducersNames.PLAYER}/${asyncActionsNames.REGISTER}`,
  async (request, thunkAPI) => {
    const { email, password, name, accesses } = request;

    const { data, error } = await register({
      email,
      password,
      name,
    });

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }

    if (accesses?.length) {
      await Promise.all(
        accesses.map((access) =>
          thunkAPI.dispatch(
            setClanMemberThunk({
              clanId: access.clanId,
              memberId: data.id,
              roleId: access.roleId,
              streamerId:
                access.roleId === roleID[EUserRole.NAVIGATOR]
                  ? access.streamerId
                  : undefined,
              isAddedFromAccount: true,
              isAddedWithNewAccount: true,
            })
          )
        )
      );
    }

    thunkAPI.dispatch(getAccountsThunk());

    return data;
  }
);

export const registerWithClanThunk = createAsyncThunk<
  ThunkAPI,
  TRegisterRequest &
    Omit<TSetClanMemberInput, 'memberId'> & {
      isAddedFromClan?: boolean;
    }
>(
  `${reducersNames.PLAYER}/${asyncActionsNames.REGISTER}`,
  async (request, thunkAPI) => {
    const {
      email,
      password,
      name,
      clanId,
      roleId,
      isAddedFromClan,
      streamerId,
    } = request;

    const { data, error } = await register({
      email,
      password,
      name,
    });

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }

    await setClanMember({ clanId, roleId, memberId: data.id, streamerId });

    if (isAddedFromClan) {
      thunkAPI.dispatch(getClanMembersThunk(request.clanId));
    } else {
      thunkAPI.dispatch(getAccountsThunk());

      notify.success(successMessages.SAVE_DATA);
    }

    return data;
  }
);

export const getAccountsThunk = createThunk<
  IListAccountsResponse,
  IListParams | undefined
>(
  `${reducersNames.PLAYER}/${asyncActionsNames.GET_ALL_PLAYERS}`,
  (params = {}) => getAccounts(params)
);

export const getAllPlayersClansThunk = createThunk<IPlayerClanResponse>(
  `${reducersNames.PLAYER}/${asyncActionsNames.GET_ALL_PLAYERS_CLANS}`,
  getAccountsClans
);
